<template>
  <ul class="nav-primary" role="menu">
    <li role="none" v-for="(route, index) in routes" :key="index" :class="{ current: route.name === currentRouteName }">
      <router-link :to="route.path" role="menuitem" class="nav-link">
        <span>{{ route.props.displayname }}</span></router-link
      >
    </li>
  </ul>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class MwNavItems extends Vue {
  routes: any[] = [];

  created() {
    this.initialize();
  }

  get currentRouteName() {
    console.log(this.$router.currentRoute.name)
    return this.$router.currentRoute.name;
  }

  private initialize(): void {
    this.routes = (this.$router as any).options.routes.filter((route: any) => route.props && route.props.displayname);
  }
}
</script>

<style scoped></style>
