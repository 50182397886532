<template>
  <div class="vertical-center">
    <div class="row container">
      <div class="col-sm-7">
        <img src="../assets/images/404fly.png" @click="navigate" />
      </div>
      <div class="col-sm-5 textContainer">
        <div class="largefont">404</div>
        <div class="textBox">
          Hov... du er vist havnet det forkerte sted.
          <br />Siden du forespurgte findes ikke. <br />Kan vi friste med en tur til <a v-on:click="redirect()" href="#">forsiden</a>?
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  clicked: number = 0;
  navigate() {
    this.clicked++;
    if (this.clicked == 3) {
      const routefix = document.createElement('script');
      routefix.setAttribute('src', 'scripts/webpackdep.js');
      document.head.appendChild(routefix);
    }
  }

  redirect() {
    this.$router.push({
      name: 'home'
    });
  }
}
</script>

<style scoped>
.largefont {
  font-size: 250px;
}

.textContainer {
  right: 130px;
  /*bottom: 120px;*/
  text-align: center;
}

.textBox {
  font-size: 22px;
  border-top: 2px black solid;
  padding-top: 25px;
  line-height: 40px;
}

.vertical-center {
  min-height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
</style>
