import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import WizardItem from '@/models/wizard';
import { MwMessageBus } from '@/views/MwMessageBus';

@Component
export default class WizardHelper extends Vue {
  @Getter('WIZARD') wizard!: WizardItem[];

  pageErrors: any[] = [];

  isValid() {
    return !this.$v || !this.$v.$invalid;
  }

  validate() {
    MwMessageBus.$emit('validate');

    this.pageErrors = this.createErrorArray();

    const isValid = this.isValid();
    const wizardItem = this.getCurrentWizardItem();

    if (wizardItem) {
      wizardItem.valid = isValid;
      wizardItem.hasErrors = this.pageErrors.length > 0;
    }
    return isValid;
  }

  goToError(element: any) {
    const top = element.offsetTop;

    // window.scrollTo(0, top);
    element.scrollIntoView();
  }

  _fillComponentsArray(c: any, componentsArray: any[]) {
    c.$children.forEach((component: any) => {
      if (component.$children.length > 0) {
        this._fillComponentsArray(component, componentsArray);
      } else {
        componentsArray.push(component);
      }
    });
  }

  createErrorArray() {
    let errorObjects = [];
    const components: any = [];
    this._fillComponentsArray(this, components);
    const componentsWithErrors = components.filter((component: any) => component.$data.validationError);

    errorObjects = componentsWithErrors.map((comp: any) => {
      return {
        errorMessage: comp.$data.validationError,
        element: comp.$el,
        inputLabel: comp.$props.label
      };
    });

    return errorObjects;
  }

  getCurrentWizardItem() {
    const currentRouterName: any = this.$router.currentRoute.name;
    return this.getWizardItem(currentRouterName);
  }

  getWizardItem(routeName: string) {
    return this.wizard.find(item => item.route === routeName);
  }

  goToRoute(routeName: string, ignoreValidation?: boolean) {
    const nextWizardItem: any = this.getWizardItem(routeName);

    if (nextWizardItem) {
      if (!ignoreValidation) {
        if (this.validate()) {
          this.$router.push({ name: nextWizardItem.route });
        }
      } else {
        this.$router.push({ name: nextWizardItem.route });
      }
    }
  }
}
