<template>
  <section ref="pejlinger">
    <div class="col-12">
      <a href="javascript:void(0);" @click="goToRoute('maengder', true)" class="back-link d-none d-lg-inline-block " type="button"
        >Tilbage</a
      >
    </div>
    <MwAlert type="error" heading="Der er problemer" v-if="pageErrors.length > 0">
      <ul class="nobullet-list">
        <li v-for="(error, index) in pageErrors" :key="index">
          <a class="function-link" @click="goToError(error.element)">{{ error.inputLabel }} - {{ error.errorMessage }}</a>
        </li>
      </ul>
    </MwAlert>
    <MwAlert type="info" heading="Info" v-if="hasValidatedItems()">
      <p>Dele af din indberetning kan være godkendt hos kommunen og derfor vil det ikke være muligt at skrive i felterne.</p>
    </MwAlert>
    <h1>Indberet pejlinger for året {{ requiredYear }}</h1>

    <MwAccordion v-for="(pejleData, index) in this.localBoringPejleData" :key="index">
      <MwAccordionItem
        :label="'Boring med DGUnr = ' + pejleData.dguNr + ' Indtagsnr. ' + pejleData.indtagNummer"
        :id="pejleData.indtagId"
        :expanded="!pejleData.erStorVandanlaeg"
      >
        <div v-if="pejleData.pejlinger.length > 0">
          <MwAlert type="info" heading="Info">
            Hvis du har foretaget en ændring på boringen eller indtaget bedes du venligst angive det i bemærkninsfeltet.
          </MwAlert>
          <div class="row">
            <div class="col-6">
              <MwSelect
                label="Pejlemetode"
                :value.sync="pejleData.jupiterPejleMetodeId"
                :validations="$v.localBoringPejleData.$each[index].jupiterPejleMetodeId"
                :options="lookups.jupiterPejleMetode"
                :disabled="pejleData.pejlinger.some(pejling => pejling.valideret)"
              />
            </div>
            <div class="col-6">
              <MwSelect
                label="Reference til pejlepunkt"
                :value.sync="pejleData.jupiterReferencepunktId"
                :validations="$v.localBoringPejleData.$each[index].jupiterReferencepunktId"
                :options="lookups.jupiterReferencepunkt"
                :infoText="referencepunktInfoTekst"
                :disabled="pejleData.pejlinger.some(pejling => pejling.valideret)"
              />
            </div>
          </div>
          <div class="row my-5">
            <div class="col-12">
              <MwTextArea
                label="Bemærkning (Hvis du har ændret noget på din boring)"
                :text.sync="pejleData.bemaerkning"
                :validations="$v.localBoringPejleData.$each[index].bemaerkning"
                :disabled="pejleData.pejlinger.some(pejling => pejling.valideret)"
              />
            </div>
          </div>
        </div>
        <MwAccordion class="mb-5" v-for="(pejling, pejlingIndex) in pejleData.pejlinger" :key="pejlingIndex">
          <MwAccordionItem
            :label="'Pejling ' + formatDate(pejling.dato) + (pejling.feltmaaling != null ? ' - måling ' + pejling.feltmaaling : '')"
            :expanded="pejling.expanded"
          >
            <div class="mt-4 align-text-right " style="float:right;" v-if="!pejling.id">
              <button class="button button-secondary  " title="Tryk for at fjerne pejling" @click="deletePejling(index, pejlingIndex)">
                <i class="icon icon-delete" />
                <span style="vertical-align: super;">
                  Fjern pejling
                </span>
              </button>
            </div>

            <div class="row">
              <div class="col-12">
                <MwDateSelector
                  label="Dato for pejling "
                  :value.sync="pejling.dato"
                  :validations="$v.localBoringPejleData.$each[index].pejlinger.$each[pejlingIndex].dato"
                  :disabled="pejling.valideret"
                  :yearDisabled="true"
                  :requiredYear="requiredYear"
                  :showTimestamp="true"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <MwSelect
                  label="Situation (I ro eller drift)"
                  :value.sync="pejling.jupiterPejleSituationId"
                  :validations="$v.localBoringPejleData.$each[index].pejlinger.$each[pejlingIndex].jupiterPejleSituationId"
                  :options="lookups.jupiterPejleSituation"
                  :disabled="pejling.valideret"
                  @change="situationChanged($event, pejling)"
                />
              </div>
              <div class="col-6">
                <MwInput
                  label="Antal timer siden pumpestop"
                  title="Indskriv timer siden pumpestop"
                  :disabled="pumpestopDisabled(pejling) || pejling.valideret"
                  :text.sync="pejling.timerSidenPumpestop"
                  :validations="$v.localBoringPejleData.$each[index].pejlinger.$each[pejlingIndex].timerSidenPumpestop"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <MwSelect
                  label="Pejle ekstremer"
                  :value.sync="pejling.jupiterPejleEkstremerId"
                  :infoText="pejleEkstremerInfoText"
                  :options.sync="lookups.jupiterPejleEkstremer"
                  :disabled="pejling.valideret"
                  @change="pejleEkstremerChange(pejling)"
                  :optional="true"
                ></MwSelect>
              </div>
              <div class="col-6">
                <MwInput
                  label="Måling"
                  title="Indskriv her din måling"
                  :text.sync="pejling.feltmaaling"
                  :infoText="maalingInfoTekst"
                  :validations="$v.localBoringPejleData.$each[index].pejlinger.$each[pejlingIndex].feltmaaling"
                  :disabled="pejling.valideret || (pejling.jupiterPejleEkstremerId !== null && pejling.jupiterPejleEkstremerId !== '')"
                />
              </div>
            </div>
          </MwAccordionItem>
        </MwAccordion>

        <hr />
        <div class="align-text-center mt-5">
          <button class="button button-secondary " title="Tryk for at tilføje ekstra pejling" @click="addPejling(index)">
            <i class="icon icon-add" />
            <span style="vertical-align: super;">
              Tilføj pejling
            </span>
          </button>
        </div>
      </MwAccordionItem>
    </MwAccordion>

    <nav class="page-navigation d-print-none">
      <!-- <button class="button button-primary" @click="goToRoute('opsummering')"> -->
      <button class="button button-primary" @click="savePejling">
        Videre
      </button>
    </nav>
  </section>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';
import Todo from '@/models/todo';

import { HttpResponse } from '@/networking/interfaces';
import MwAccordion from '@/components/mwUtils/accordions/MwAccordion.vue';
import MwAccordionItem from '@/components/mwUtils/accordions/MwAccordionItem.vue';
import MwDateSelector from '@/components/mwUtils/mwInputs/MwDateSelector.vue';
import MwSelect from '@/components/mwUtils/mwInputs/MwSelect.vue';
import MwTextArea from '@/components/mwUtils/mwInputs/MwTextArea.vue';
import MwAlert from '@/components/mwUtils/alerts/MwAlert.vue';

import MwInput from '@/components/mwUtils/mwInputs/MwInput.vue';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';
import Indberetning, { OppumpedeMaengderAnlaeg, IndberetningDataBoring, BoringPejleData } from '@/models/indberetning';
import {
  required,
  maxLength,
  minLength,
  numeric,
  minValue,
  between,
  maxValue,
  decimal,
  requiredIf,
  integer,
  helpers
} from 'vuelidate/lib/validators';
import { Pejling } from '@/models/indberetning';

import WizardHelper from '@/mixins/WizardHelper';
import moment, { Moment } from 'moment';
import { decimalCustom, integerCustom, minValueDecimal, kote } from '@/utils/validators';
import { MwMessageBus } from '@/views/MwMessageBus';

@Component({
  components: { MwAccordionItem, MwAccordion, MwDateSelector, MwSelect, MwTextArea, MwInput, MwAlert },
  validations: {
    localBoringPejleData: {
      $each: {
        pejlinger: {
          $each: {
            dato: {
              required
            },
            jupiterPejleSituationId: {
              required: requiredIf(function(model) {
                return !model.valideret;
              })
            },
            timerSidenPumpestop: {
              decimalCustom,
              minValueDecimal: minValueDecimal(0),
              required: requiredIf(function(model) {
                //@ts-ignore
                const situationObj = this.lookups.jupiterPejleSituation.find((situation: any) => {
                  return model.jupiterPejleSituationId !== null && situation.id === model.jupiterPejleSituationId.toString();
                });

                return situationObj && situationObj.navn.toLowerCase().includes('i ro') && !model.valideret;
              })
            },
            feltmaaling: {
              required: requiredIf(function(model) {
                return model.jupiterPejleEkstremerId === null ? true : false;
              }),
              decimalCustom
            }
          }
        },
        jupiterPejleMetodeId: {
          required: requiredIf(function(model) {
            return model.pejlinger.length > 0;
          })
        },
        jupiterReferencepunktId: {
          required: requiredIf(function(model) {
            return model.pejlinger.length > 0;
          }),
          kote
        },
        bemaerkning: { maxLength: maxLength(200) }
      }
    }
  }
})
export default class Pejlinger extends Mixins(WizardHelper) {
  @Getter('GET_ANCHOR')
  anchor!: string;

  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;
  @Getter('INDBERETNINGLOOKUPS', { namespace: STORE_NS_LOOKUPS })
  lookups!: any;

  referencepunktInfoTekst: string =
    'Vandspejlets højde kan måles på forskellig måder afhængigt at måleudstyret.<br/>Kote: Koten er vandspejlets højde over havoverfladen.</br>Pejlepunkt: Meter under pejlepunkt er afstanden til vandspejlet fra et fast defineret målepunkt.</br>Terræn: Meter under terræn er afstanden fra terræn, dvs. hvor langt nede er vandspejlet.';
  maalingInfoTekst: string = 'Hvis boringen er artetisk/overtryk, angives værdien med negativt fortegn.';
  pejleEkstremerInfoText: string =
    'Kodeliste fra Jupiter. Her angives om der har været nogle ekstreme forhold ved pejlingen (tør eller overløb)';

  localBoringPejleData: BoringPejleData[];

  requiredYear!: number;

  @Watch('anchor')
  anchorChanged() {
    //Amen det er trist.
    //Problemet er at efter man først har været på siden "Indberet pejlinger" for efterfølgende at gå over til fx. "Indberet mængder"
    //så resultere det i at den ikke kan scrolle hvis man klikker på et punkt under "Indberet pejlinger"
    //Derfor dette hack med timeout for at fixe.. En bedre løsning kunne evt. være en URL rute ligesom i MW men det er en større opg
    this.scrollToAnchor();
    setTimeout(() => {
      this.scrollToAnchor();
    }, 300);
  }

  mounted() {
    this.scrollToAnchor();
  }

  constructor() {
    super();
    this.localBoringPejleData = [];
  }

  isCorrectYear(value: any) {
    if (value == null) {
      return false;
    }

    const momentObj = moment(value);

    if (momentObj.isValid()) {
      const inputYear = momentObj.year();
      //@ts-ignore
      const isCorrectYear = inputYear === this.indberetning.aar;
      return isCorrectYear;
    }

    return false;
  }

  situationChanged(event: any, model: any) {
    const value = event.target.value;

    const situationObj = this.lookups.jupiterPejleSituation.find((situation: any) => {
      return situation.id === value;
    });

    if (situationObj && situationObj.navn.toLowerCase().includes('i drift')) {
      model.timerSidenPumpestop = null;

      //TODO only reset validation for maalerstandStart, maalerstandSlut and omregningsfaktor
      //TODO the current solution reset validations for all element
      MwMessageBus.$emit('resetValidation');
    }
  }

  pejleEkstremerChange(pejling: Pejling) {
    if (pejling.jupiterPejleEkstremerId) {
      pejling.feltmaaling = null;
    }
  }

  formatDate(date: string) {
    const momentObj = moment(date);

    if (momentObj.isValid()) {
      return moment(date).format('DD-MM-YYYY');
    }

    return '';
  }

  formatDateTime(date: string) {
    const momentObj = moment(date);

    if (momentObj.isValid()) {
      return moment(date).format('DD-MM-YYYY HH:mm');
    }

    return '';
  }

  async created() {
    this.localBoringPejleData = this.indberetning.boringPejleData;
    this.requiredYear = this.indberetning.aar;

    setTimeout(() => {
      this.validate();
    }, 200);
  }

  hasValidatedItems() {
    return this.localBoringPejleData.some(pejleData => pejleData.pejlinger.some(pejling => pejling.valideret));
  }

  addPejling(boringIndex: any) {
    const pejling = Object.assign(new Pejling(), {
      dato: moment(this.requiredYear + '-01-01').format('YYYY-MM-DD HH:mm:ssZ')
    });
    if (this.localBoringPejleData[boringIndex]) {
      this.localBoringPejleData[boringIndex].pejlinger.push(pejling);
    }
  }

  deletePejling(pejleDataIndex: any, pejlingIndex: any) {
    const result = confirm('Er du sikker på at du vil fjerne denne pejling');
    if (result) {
      this.localBoringPejleData[pejleDataIndex].pejlinger = this.localBoringPejleData[pejleDataIndex].pejlinger.filter((pejlinger, i) => {
        return i !== pejlingIndex;
      });
    }
  }

  pumpestopDisabled(model: any) {
    const situationObj = this.lookups.jupiterPejleSituation.find((situation: any) => {
      return model.jupiterPejleSituationId !== null && situation.id === model.jupiterPejleSituationId.toString();
    });

    return situationObj && situationObj.navn.toLowerCase().includes('i drift');
  }

  savePejling() {
    if (this.validate()) {
      //Tjek boringer for pejlinger med identiske datoer
      const boringerMedDubleredeDatoer: string[] = [];
      this.localBoringPejleData.forEach(boring => {
        if ([...new Set(boring.pejlinger.map(x => x.dato))].length != boring.pejlinger.length) {
          boringerMedDubleredeDatoer.push(boring.indtagId);
        }
      });

      if (boringerMedDubleredeDatoer.length > 0) {
        const errorMessage =
          'Du har indtastet flere pejlinger med identisk dato og tidspunkt på samme boring og indtag, ' +
          'de vil derfor blive overskrevet af hinanden. Hvis det er en fejl, skal du i stedet indtaste den rigtige dato og tidspunkt' +
          '\n\nFølgende er berørte:\n' +
          this.localBoringPejleData
            .filter(x => boringerMedDubleredeDatoer.includes(x.indtagId))
            .map(x => `    - DGUnr =${x.dguNr} Indtagsnr. ${x.indtagNummer}`)
            .join('\n');
        const result = confirm(errorMessage);
        if (result == false) {
          return;
        }
      }

      this.goToRoute('opsummering');
      window.scrollTo(0, 0);
    } else {
      document.querySelectorAll('.accordion-button').forEach(element => element.setAttribute('aria-expanded', 'true'));
      document.querySelectorAll('.accordion-content').forEach(element => element.setAttribute('aria-hidden', 'false'));
      window.scrollTo(0, 0);
    }
  }

  scrollToAnchor() {
    if (!this.anchor) {
      return;
    }

    const el = this.$refs.pejlinger as Element;
    const anchorElement = el.querySelector(this.anchor) as HTMLElement;
    if (anchorElement) {
      anchorElement.scrollIntoView(true);

      const ariaExpanded = anchorElement.attributes.getNamedItem('aria-expanded') as Attr;
      if (ariaExpanded && ariaExpanded.value === 'false') {
        anchorElement.click();
      }
      //window.scrollBy(0, -60);
    }
  }
}
</script>

<style scoped></style>
