<template>
  <div class="row">
    <aside class="col-12 col-lg-3 sidebar-col" style="min-height:400px;">
      <MwOverflowMenu :items="wizard" :currentRouteName="this.currentWizardRouteName" @itemSelected="itemSelected" @boringSelected="boringSelected"></MwOverflowMenu>
    </aside>
    <main class="col-12 col-lg-9">
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view ref="viewElement"></router-view>
        </keep-alive>
      </transition>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';
//@ts-ignore
import Header from '@/components/header/Header.vue';
import Footer from '@/components/Footer.vue';
import MwOverflowMenu from '@/components/header/MwOverflowMenu.vue';
import MwNavItems from '@/components/header/MwNavItems.vue';
import Indberetning, { BoringPejleData } from '@/models/indberetning';
import { Route } from 'vue-router';
import { Mutation } from 'vuex-class';
import WizardItem from '@/models/wizard';
import WizardHelper from '@/mixins/WizardHelper';
// import router

@Component({
  components: {
    MwNavItems,
    Header,
    Footer,
    MwOverflowMenu
  }
})
export default class Wizards extends Mixins(WizardHelper) {
  @Mutation('SET_WIZARD')
  setWizard!: (wizard: WizardItem[]) => void;

  @Mutation('SET_ANCHOR')
  setAnchor!: (anchor: string) => void;

  routeName: string = 'indberet';
  currentWizardRouteName = '';
  routes: any[] = [];

  constructor() {
    super();
  }

  created() {
    this.setWizardData();
    this.setCurrentWizardRouteName(this.$router.currentRoute.name);
  }

  setCurrentWizardRouteName(routeName: string | null | undefined) {
    if (routeName) {
      this.currentWizardRouteName = routeName;
    }
  }

  @Watch('$route')
  onAnyRouteChanged(to: Route, from: Route) {
    if (to.name !== from.name) {
      this.setCurrentWizardRouteName(to.name);
    }
  }

  itemSelected(item: any) {
    if (this.getCurrentWizardItem() !== item) {
      //Very hacky solution, but it works.
      /* @ts-ignore */
      if (this.$refs.viewElement.validate) {
        /* @ts-ignore */
        this.$refs.viewElement.validate();

        this.$router.push({ name: item.route });

        // if (this.$refs.viewElement.validate()) {
        //   this.$router.push({ name: item.route });
        // }
      }
    }
  }

  boringSelected(item: any, boring: BoringPejleData) {
    this.itemSelected(item);
    this.setAnchor('#accordionItem' + boring.indtagId);
  }

  setWizardData() {
    const routes = (this.$router as any).options.routes.find((route: any) => route.name === this.routeName).children;
    const wizard: WizardItem[] = routes.map((route: any) => {
      return {
        route: route.name,
        displayname: route.props.displayname,
        valid: false,
        hasErrors: false,
        enabled: false
      };
    });

    this.setWizard(wizard);
  }
}
</script>
