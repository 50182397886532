import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import router from './router';
import Component from 'vue-class-component';
import Vuelidate from 'vuelidate';
import { texts } from './texts';
import 'bootstrap';

const Multiselect = () => import('vue-multiselect');

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.config.productionTip = false;

Vue.component('multiselect', Multiselect);
Vue.prototype.$t = texts;
Vue.use(Vuelidate);
new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app');
