<template>
  <div class="form-group" :class="{ 'form-error': validationError !== '' }">
    <fieldset>
      <legend class="form-label">{{ label }}</legend>
      <span class="form-hint" :id="label">For eksempel: 01 01 {{ requiredYear }}</span>
      <span class="form-error-message" id="example-name-error" v-if="this.validationError !== ''">
        <span class="sr-only">Fejl:</span> {{ this.validationError }}
      </span>
      <div class="date-group js-calendar-group mt-3">
        <div class="form-group form-group-day">
          <label class="form-label" :for="dayElementId">Dag</label>
          <input
            class="form-input js-calendar-day-input"
            :id="dayElementId"
            v-model="dayText"
            :validations="$v.dayText"
            @blur="updateInternalMoment($event)"
            type="number"
            :disabled="disabled"
            autocomplete="off"
            :required="isRequired"
          />
        </div>
        <div class="form-group form-group-month">
          <label class="form-label" :for="monthElementId">Måned</label>
          <input
            class="form-input js-calendar-month-input"
            :id="monthElementId"
            v-model="monthText"
            @blur="updateInternalMoment($event)"
            type="number"
            :disabled="disabled"
            autocomplete="off"
            :required="isRequired"
          />
        </div>
        <div class="form-group form-group-year ">
          <label class="form-label" :for="yearElementId">År</label>
          <input
            class="form-input js-calendar-year-input"
            :id="yearElementId"
            v-model="yearText"
            @blur="updateInternalMoment($event)"
            type="number"
            :disabled="disabled || yearDisabled"
            autocomplete="off"
            :required="isRequired"
          />
        </div>
        <template v-if="showTimestamp">
          <div class="form-group form-group-day " style="margin-right:3px;">
            <label class="form-label" :for="hourElementId">Time</label>
            <input
              class="form-input"
              :id="hourElementId"
              v-model="hourText"
              type="number"
              :disabled="disabled"
              @blur="updateInternalMoment($event)"
              autocomplete="off"
              :required="isRequired"
              :aria-required="areaRequired"
            />
          </div>
          <div style="margin-bottom:6px;">:</div>
          <div class="form-group form-group-day " style="margin-left:3px;">
            <label class="form-label" :for="minuteElementId">Minut</label>
            <input
              class="form-input"
              :id="minuteElementId"
              @blur="updateInternalMoment($event)"
              v-model="minuteText"
              type="number"
              :disabled="disabled"
              autocomplete="off"
              :required="isRequired"
              :aria-required="areaRequired"
            />
          </div>
        </template>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import moment from 'moment';
import { Guid } from 'guid-typescript';
import { MwMessageBus } from '@/views/MwMessageBus';
import { required, decimal, maxLength, minLength, numeric, minValue, between, maxValue } from 'vuelidate/lib/validators';

@Component({
  validations: {
    minuteText: {
      minValue: minValue(0),
      maxValue: maxValue(59),
      maxLength: maxLength(2)
    },
    hourText: {
      minValue: minValue(0),
      maxValue: maxValue(23),
      maxLength: maxLength(2)
    },
    dayText: {
      minValue: minValue(1),
      maxValue: maxValue(31),
      maxLength: maxLength(2)
    },
    monthText: {
      minValue: minValue(1),
      maxValue: maxValue(12),
      maxLength: maxLength(2)
    },
    yearText: {
      minLength: minLength(4),
      maxLength: maxLength(4)
    }
  }
})
export default class MwDateSelector extends Vue {
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) hintText!: string;
  @Prop({ default: 'Fejl' }) errorText!: string;
  @Prop({ default: '' }) value!: string;

  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) title!: any;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: false }) showTimestamp!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) yearDisabled!: boolean;
  @Prop({}) requiredYear!: number;

  @Watch('internalMomentObject')
  updateValue(momentObject: moment.Moment) {
    //this.value = momentObject.toString();
    this.updateInputValues();
  }
  internalMomentObject: moment.Moment | null = null;
  minuteText: string = '';
  hourText: string = '';
  dayText: string = '';
  monthText: string = '';
  yearText: string = '';

  dayElementId: string = 'txtDay' + Guid.create().toString();
  monthElementId: string = 'txtMonth' + Guid.create().toString();
  yearElementId: string = 'txtYear' + Guid.create().toString();
  hourElementId: string = 'txtHour' + Guid.create().toString();
  minuteElementId: string = 'txtMinute' + Guid.create().toString();
  validationError: string = '';
  constructor() {
    super();

    // this.updateInputValues();
  }

  mounted() {
    this.internalMomentObject = this.value == null ? null : moment(this.value);
  }

  created() {
    MwMessageBus.$on('validate', () => {
      this.validateInput();
      // this.validateScrollTo();
    });

    MwMessageBus.$on('resetValidation', () => (this.validationError = ''));
  }

  updateInputValues() {
    if (this.internalMomentObject) {
      if (this.showTimestamp) {
        this.minuteText = this.internalMomentObject.minute().toString();
        this.hourText = this.internalMomentObject.hour().toString();
      }

      this.dayText = this.internalMomentObject.date().toString();
      this.monthText = '' + (this.internalMomentObject.month() + 1);
      this.yearText = this.internalMomentObject.year().toString();
    }
  }

  updateInternalMoment(event: any) {
    this.validateInput();

    if (!this.validations.$invalid) {
      if (!this.internalMomentObject) {
        this.internalMomentObject = moment();
      }

      if (this.showTimestamp) {
        this.internalMomentObject.minute(parseInt(this.minuteText, 10));
        this.internalMomentObject.hour(parseInt(this.hourText, 10));
      }

      this.internalMomentObject.date(parseInt(this.dayText, 10));
      this.internalMomentObject.month(parseInt(this.monthText, 10) - 1);
      this.internalMomentObject.year(parseInt(this.yearText, 10));

      this.$emit('update:value', this.internalMomentObject.format('YYYY-MM-DD HH:mm:ssZ'));
    }
  }
  validateInput() {
    this.validationError = '';
    if (this.validations) {
      if (!this.validationError && this.validations.$params.required) {
        const isMinuteValid = this.minuteText != null && this.minuteText != '';
        const isHourValid = this.hourText != null && this.hourText != '';
        const isDayValid = this.dayText != null && this.dayText != '';
        const isMonthValid = this.monthText != null && this.monthText != '';
        const isYearValid = this.yearText != null && this.yearText != '';

        if (!isDayValid || !isMonthValid || !isYearValid) {
          this.validationError = 'Felterne er påkrævede';
        }

        if (this.showTimestamp) {
          if (!isMinuteValid || !isHourValid) {
            this.validationError = 'Felterne er påkrævede';
          }
        }
        if (this.$v.minuteText.$invalid) {
          this.validationError = 'Minutfeltet er ikke udfyldt korrekt';
        }

        if (this.$v.hourText.$invalid) {
          this.validationError = 'Timefeltet er ikke udfyldt korrekt';
        }

        if (this.$v.dayText.$invalid) {
          this.validationError = 'Dagfeltet er ikke udfyldt korrekt';
        }

        if (this.$v.monthText.$invalid) {
          this.validationError = 'Månedfeltet er ikke udfyldt korrekt';
        }

        if (this.$v.yearText.$invalid) {
          this.validationError = 'Årfeltet er ikke udfyldt korrekt';
        }

        if (this.requiredYear) {
          if (this.yearText.toString() !== this.requiredYear.toString()) {
            this.validationError = 'Årfeltet skal være ' + this.requiredYear;
          }
        }

        if (!this.validationError) {
          if (this.monthText && this.dayText) {
            const correctMonthText = this.monthText.length === 1 ? '0' + this.monthText : this.monthText;
            const correctDayText = this.dayText.length === 1 ? '0' + this.dayText : this.dayText;

            if (this.showTimestamp) {
              //TODO
            }

            const dateText = this.yearText + '-' + correctMonthText + '-' + correctDayText;
            const momentObj = moment(dateText, true);
            if (!momentObj.isValid()) {
              this.validationError = 'Datoen er ikke korrekt';
            }
          }
        }
      }
    }
  }

  get isRequired() {
    return this.validations != null && this.validations.$params != null && this.validations.$params.required;
  }
  get areaRequired() {
    if (this.isRequired) {
      return true;
    }
  }
}
</script>

<style scoped></style>
