<template>
  <div>
    <h3 v-if="opsummeringTitle === null" class="h4">{{ opsummeringTitle }}</h3>
    <!-- <p class="font-lead">{{ opsummeringText }}</p>
    <h2>{{ opsummeringSubtitle }}</h2> -->
    <table class="table table--borderless  table--extracompact table--responsive-headers d-print-table mb-5 ">
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { Accordion } from 'dkfds';

@Component
export default class MwOpsummering extends Vue {
  @Prop({ default: '' }) opsummeringTitle!: string;
  @Prop({ default: '' }) opsummeringText!: string;
  @Prop({ default: '' }) opsummeringSubtitle!: string;
  @Prop({ default: '' }) label!: any;

  @Prop({ default: null }) validations!: vuelidate.Params;

  mounted() {
    //TODO DO NOT USE setTimeout
    setTimeout(() => {
      const accordion = new Accordion(this.$el);
    }, 1000);
  }
}
</script>

<style scoped></style>
