<template>
  <section>
    <MwAlert type="success" heading="Kvittering" textHeading="Kvittering" text="Dine indtastede oplysninger er modtaget">
      <p v-html="indberetning.kvitteringTekst"></p>
    </MwAlert>
    <h1>Kvittering</h1>

    <!-- <h1 class="mt-5">Kvittering</h1> -->
    <p class="font-lead">Nedenfor finder du dine indtastninger fra de foregående sider.</p>
    <button class="button button-secondary d-print-none mb-7" @click="printWindow">
      <i class="icon icon-printer" /> <span style="vertical-align: super;">Udskriv kvittering</span>
    </button>
    <OpsummeringKvittering :showEditLinks="false" />
  </section>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';
import Todo from '@/models/todo';
import MwAlert from '@/components/mwUtils/alerts/MwAlert.vue';
import OpsummeringKvittering from '@/views/OpsummeringKvittering.vue';
import { HttpResponse } from '@/networking/interfaces';
import { STORE_NS_INDBERETNING } from '@/constants';
import Indberetning from '@/models/indberetning';

@Component({
  components: { MwAlert, OpsummeringKvittering }
})
export default class Kvittering extends Vue {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;
  mounted() {
    console.log('todo!');
  }
  printWindow() {
    window.print();
  }
}
</script>

<style scoped></style>
