import { ActionTree } from 'vuex';
import { RootState, IndberetningState, LookupState } from '@/store/statetypes';
import { QueryOptions, HttpResponse } from '@/networking/interfaces';
import { IndberetningContext } from '@/store/statetypes';

import * as httpClient from '@/networking/httpclient';
import UrlBuilder from '@/networking/urlBuilder';
import Indberetning from '@/models/indberetning';

export const actions: ActionTree<LookupState, RootState> = {
  async FETCH_INDBERETNINGLOOKUPS({ commit, state, rootGetters }) {
    const linkId = rootGetters.LINKID;
    await Promise.all([
      httpClient.get('EksternVand/list/jupiterindvindingsmetode?linkId=' + linkId).then(r => {
        r.data.forEach((element: any, index: number) => {
          if (element.navn === 'Skøn') {
            r.data[index].navn = 'Skøn/Oppumpede vandmængde (m3)';
          }
        });
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'indvindingsmetode' });
      }),
      httpClient.get('EksternVand/list/jupiterpejlesituation?linkId=' + linkId).then(r => {
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'jupiterPejleSituation' });
      }),
      httpClient.get('EksternVand/list/jupiterpejlemetode?linkId=' + linkId).then(r => {
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'jupiterPejleMetode' });
      }),
      httpClient.get('EksternVand/list/jupiterreferencepunkt?linkId=' + linkId).then(r => {
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'jupiterReferencepunkt' });
      }),
      httpClient.get('EksternVand/list/jupiterpejleekstremer?linkId=' + linkId).then(r => {
        r.data.unshift({
          id: null,
          kode: '',
          navn: '-'
        });
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'jupiterPejleEkstremer' });
      }),
      httpClient.get('EksternVand/list/jupiterforsyningsart?linkId=' + linkId).then(r => {
        commit('UPDATE_LOOKUPLIST', { data: r.data, key: 'jupiterForsyningsart' });
      })
    ]);
  }
};
