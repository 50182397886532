<template>
  <footer>
    <div class="footer">
      <div class="container">
        <div class="align-text-left">
          <ul class="unstyled-list inline-list">
            <li class="" v-if="indberetning.afdelingsNavn">
              <span class="h5 weight-semibold">{{ indberetning.afdelingsNavn }}</span>
              <span class="ml-4 mr-4" style="font-weight: 100;">•</span>
            </li>

            <li class="" v-if="indberetning.afdelingEmail">
              <a :href="'mailto:' + indberetning.afdelingEmail" class="function-link"> {{ indberetning.afdelingEmail }}</a>
              <span class="ml-3 mr-3" style="font-weight: 100;">•</span>
            </li>

            <li class="" v-if="indberetning.afdelingTelefon">
              <a :href="'tel:' + indberetning.afdelingTelefon" class="function-link">(+45) {{ indberetning.afdelingTelefon }}</a>
              <span class="ml-3 mr-3" style="font-weight: 100;">•</span>
            </li>

            <li class="d-print-none" v-if="indberetning.linkTilWebtilgaengelighed">
              <a :href="indberetning.linkTilWebtilgaengelighed" target="_blank" class="function-link icon-link">Tilgængelighedserklæring</a>
              <span class="ml-3 mr-3" style="font-weight: 100;">•</span>
            </li>

            <li class="d-print-none" v-if="indberetning.linkTilPrivatlivspolitik">
              <a :href="indberetning.linkTilPrivatlivspolitik" target="_blank" class="function-link icon-link"
                >Privatlivspolitik (cookies)</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';

import { HttpResponse } from '@/networking/interfaces';
import Indberetning from '@/models/indberetning';
import { STORE_NS_INDBERETNING } from '@/constants';
@Component
export default class Footer extends Vue {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;
}
</script>

<style scoped>
/* Fixes the Arc Toolkit error */
.footer div div ul li::before,
.footer div div ul li span::after,
.footer div div ul li a::after,
.footer div div ul li:not(:last-child)::after {
  content: none !important;
}
</style>
