<template>
  <div class="form-group" :class="{ 'form-error': validationError !== '' }">
    <!-- <label class="form-label " :for="id"> {{ label }}</label> -->
    <label class="form-label " :for="textAreaId" v-show="label !== ''">
      <template v-if="isRequired">{{ label }} *</template>
      <template v-else>{{ label }}</template>
    </label>
    <span class="form-error-message" id="example-name-error" v-if="this.validationError !== ''">
      <span class="sr-only">Fejl:</span> {{ this.validationError }}
    </span>
    <textarea
      class="form-input "
      @blur="blur"
      :placeholder="placeholder"
      :id="textAreaId"
      :disabled="disabled"
      @input="input($event)"
      name="input-area"
      :maxlength="maxLength"
      :value="text"
      :rows="rows"
      :required="isRequired"
      :aria-required="areaRequired"
      autocomplete="off"
    ></textarea>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { Guid } from 'guid-typescript';
import { MwMessageBus } from '@/views/MwMessageBus';

@Component
export default class MwTextArea extends Vue {
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '5' }) rows!: string;
  @Prop({ default: 'Bemærkning' }) label!: any;
  @Prop({ default: '' }) placeholder!: any;
  @Prop({ default: 'Indskriv en bemærkning' }) title!: any;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: '200' }) maxLength!: any;
  @Prop({ default: false }) disabled!: boolean;

  id: string = 'textArea' + performance.now().toFixed(0);
  input(event: any) {
    this.$emit('update:text', event.target.value);
    // if (this.validationError) {
    //   this.validateInput();
    // }
  }
  textAreaId: string = 'input' + Guid.create().toString();
  validationError: string = '';

  blur() {
    // this.$emit('blur');
    this.validateInput();
  }

  created() {
    MwMessageBus.$on('validate', () => {
      this.validateInput();
      // this.validateScrollTo();
    });

    MwMessageBus.$on('resetValidation', () => (this.validationError = ''));
  }

  validateInput() {
    this.validationError = '';
    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = 'Feltet er påkrævet'; //this.$t.errorRequried;
      }
      if (!this.validationError && this.validations.minLength === false) {
        this.validationError = ''; //this.$t.errorMinLength(this.validations.$params.minLength.min);
      }
      if (!this.validationError && this.validations.maxLength === false) {
        this.validationError = ''; //this.$t.errorMaxLength(this.validations.$params.maxLength.max);
      }
      if (!this.validationError && this.validations.decimal === false) {
        this.validationError = ''; //this.$t.errorDecimal;
      }
      if (!this.validationError && this.validations.minValue === false) {
        this.validationError = `Feltets minimumværdi er ${this.validations.$params.minValue.min}`; //this.$t.errorMinValue(this.validations.$params.minValue.min);
      }
      if (!this.validationError && this.validations.maxValue === false) {
        this.validationError = ''; //this.$t.errorMaxValue(this.validations.$params.maxValue.max);
      }
      if (!this.validationError && this.validations.numeric === false) {
        this.validationError = ''; //this.$t.errorNumeric;
      }
      if (!this.validationError && this.validations.email === false) {
        this.validationError = ''; //this.$t.errorEmail;
      }
      if (!this.validationError && this.validations.url === false) {
        this.validationError = ''; //this.$t.errorUrl;
      }
      if (!this.validationError && this.validations.minValueDecimal === false) {
        this.validationError = ''; //this.$t.errorMinValue(this.validations.$params.minValueDecimal.min);
      }
      if (!this.validationError && this.validations.kote === false) {
        this.validationError = this.$t.errorKote;
      }
      if (!this.validationError && this.validations.maxValueDecimal === false) {
        this.validationError = ''; //this.$t.errorMaxValue(this.validations.$params.maxValueDecimal.min);
      }
    }
  }

  get isRequired() {
    return this.validations != null && this.validations.$params != null && this.validations.$params.required;
  }
  get areaRequired() {
    if (this.isRequired) {
      return true;
    }
  }
}
</script>

<style scoped>
textarea.form-input {
}
</style>
