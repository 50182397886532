import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $t: Texts;
  }
}

export class Texts {
  warningRequried: string = 'Feltet er påkrævet af DMA';
  errorRequried: string = 'Feltet er påkrævet';
  errorFileRequired: string = 'Minimum en fil er påkrævet';
  errorAddressRequired: string = 'En addresse er påkrævet';
  errorNumeric: string = 'Feltet kan kun være heltal';
  errorDecimal: string = 'Det indtastede tal er ikke i korrekt format (Eg. x.xxx,xx)';
  errorKote: string = 'Koten har ingen tilknyttet værdi';
  errorEmail: string = 'Feltet skal være en e-mailadresse';
  errorUrl: string = 'Feltet skal være en gyldig URL. Eksempel: http://www.miljoeweb.dk';
  errorMinValue(input0: number) {
    return 'Feltets minimumværdi er {0}'.replace('{0}', input0.toString());
  }
  errorMaxValue(input0: number) {
    return 'Feltets maximumværdi er {0}'.replace('{0}', input0.toString());
  }
  errorMinLength(input0: number) {
    return 'Feltet skal minimum bestå af {0} karakterer'.replace('{0}', input0.toString());
  }
  errorMaxLength(input0: number) {
    return 'Feltet skal maximum bestå af {0} karakterer'.replace('{0}', input0.toString());
  }
  errorMaxDecimalLengthCustom(input0: number) {
    return 'Feltet skal maximum bestå af {0} decimaler'.replace('{0}', input0.toString());
  }
  errorCpr: string = 'Er ikke et valid CPR-nummer';
  errorEan: string = 'Er ikke et valid EAN-nummer (starter med 57 og er 13 ciffer langt)';
}

export const texts = new Texts();
