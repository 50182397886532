<template>
  <section>
    <div class="col-12">
      <a href="javascript:void(0);" @click="goToRoute('home', true)" class="back-link d-none d-lg-inline-block" type="button">Tilbage</a>
    </div>
    <MwAlert type="error" heading="Der er problemer" v-if="pageErrors.length > 0">
      <ul class="nobullet-list">
        <li v-for="(error, index) in pageErrors" :key="index">
          <a class="function-link" @click="goToError(error.element)">{{ error.inputLabel }} - {{ error.errorMessage }}</a>
        </li>
      </ul>
    </MwAlert>
    <MwAlert type="info" heading="Info" v-if="hasValidatedItems()">
      <p>Dele af din indberetning kan være godkendt hos kommunen og derfor vil det ikke være muligt at skrive i felterne.</p>
    </MwAlert>
    <h1>Indberet mængder for året {{ requiredYear }}</h1>

    <div v-if="hasVandanlaeg()">
      <h2 class="h3">Oppumpede mængder på vandanlæg</h2>
      <MwAccordion v-for="(vandanlaeg, anlaegIndex) in localVandanlaegVandData" :key="anlaegIndex">
        <MwAccordionItem :label="vandanlaeg.navn + ', ' + vandanlaeg.adresse + ' (' + vandanlaeg.jupiterId + ')'" :expanded="true">
          <MwAccordion class="custom-padding">
            <MwAccordionItem :label="`Oppumpede vandmængder ${shouldBeOptional ? '(Råvand)' : ''}`" :expanded="true">
              <div class="row" style="width: 100%">
                <div class="col-sm-6">
                  <MwSelect
                    label="Metode for måling"
                    :value.sync="vandanlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId"
                    :options="lookups.indvindingsmetode"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId"
                    :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                    @change="maalingChanged($event, vandanlaeg.oppumpedeMaengderAnlaeg)"
                    :infoText="metodeForMaalingInfoTekst"
                  ></MwSelect>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    ref="omregningsfaktor"
                    label="Omregningsfaktor"
                    title="Indskriv omregningsfaktor"
                    :text.sync="vandanlaeg.oppumpedeMaengderAnlaeg.omregningsfaktor"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.omregningsfaktor"
                    :disabled="isSkoen(vandanlaeg.oppumpedeMaengderAnlaeg) || vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                    @blur="calculateGrundvand(vandanlaeg.oppumpedeMaengderAnlaeg, anlaegIndex)"
                    :infoText="omregningsfaktorInfoTekst"
                  >
                  </MwInput>
                </div>
              </div>
              <div class="row" width="100%" v-if="isVandmaalerMetode(vandanlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId)">
                <div class="col-sm-6 mt-3">
                  <div class="form-check" v-for="vandmaalerSubMetodeOption in VandmaalerSubMetode.methods">
                    <input
                      :name="`${VandmaalerSubMetode.name}:${vandanlaeg.virksomhedId}`"
                      class="form-check-input"
                      :id="vandmaalerSubMetodeOption.id"
                      type="radio"
                      :value="vandmaalerSubMetodeOption.id"
                      v-model="vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode"
                      @change="vandmaalerSubMetodeChanged($event, vandanlaeg.oppumpedeMaengderAnlaeg)"
                    />
                    <label class="form-check-label">{{ vandmaalerSubMetodeOption.methodName }}</label>
                  </div>
                </div>
              </div>
              <div class="row" style="width: 100%">
                <div class="col-sm-6">
                  <MwInput
                    label="Målerstart"
                    title="Indskriv målerstart"
                    :text.sync="vandanlaeg.oppumpedeMaengderAnlaeg.maalerstandStart"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.maalerstandStart"
                    :disabled="
                      isSkoen(vandanlaeg.oppumpedeMaengderAnlaeg) ||
                      vandanlaeg.oppumpedeMaengderAnlaeg.valideret ||
                      vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode == VandmaalerSubMetode.SummeretMaengde.id
                    "
                    @blur="calculateGrundvand(vandanlaeg.oppumpedeMaengderAnlaeg, anlaegIndex)"
                    ref="maalerstandStart"
                  >
                  </MwInput>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    label="Målerslut"
                    title="Indskriv målerslut"
                    :text.sync="vandanlaeg.oppumpedeMaengderAnlaeg.maalerstandSlut"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.maalerstandSlut"
                    :disabled="
                      isSkoen(vandanlaeg.oppumpedeMaengderAnlaeg) ||
                      vandanlaeg.oppumpedeMaengderAnlaeg.valideret ||
                      vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode === VandmaalerSubMetode.SummeretMaengde.id
                    "
                    @blur="calculateGrundvand(vandanlaeg.oppumpedeMaengderAnlaeg, anlaegIndex)"
                  ></MwInput>
                </div>
              </div>

              <div class="row mb-5" style="width: 100%">
                <div class="col-sm-6">
                  <MwInput
                    :label="vandanlaeg.oppumpedeMaengderAnlaeg.oppumpetFra"
                    title="Feltet er skrivebeskyttet"
                    :text.sync="vandanlaeg.oppumpedeMaengderAnlaeg.grundvand"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.grundvand"
                    :disabled="
                      !(
                        isSkoen(vandanlaeg.oppumpedeMaengderAnlaeg) ||
                        vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode == VandmaalerSubMetode.SummeretMaengde.id
                      ) || vandanlaeg.oppumpedeMaengderAnlaeg.valideret
                    "
                  >
                  </MwInput>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    label="Sidste års mængde (m3)"
                    title="Feltet er skrivebeskyttet"
                    :text="vandanlaeg.sidsteAarsMaengde"
                    :disabled="true"
                  ></MwInput>
                </div>
              </div>
              <div class="row mb-5" style="width: 100%">
                <div class="col-12">
                  <MwTextArea
                    label="Bemærkning"
                    :text.sync="vandanlaeg.oppumpedeMaengderAnlaeg.bemaerkning"
                    :validations="$v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.bemaerkning"
                  />
                </div>
              </div>
            </MwAccordionItem>
          </MwAccordion>
          <template v-if="!vandanlaeg.vandanlaegKunOppumpetMaengder">
            <MwAccordion class="custom-padding" v-if="vandanlaeg.importExportData !== null && !optionalEkstraForsyning">
              <MwAccordionItem label="Importerede og eksporterede vandmængder" :expanded="true">
                <div class="subcontainer">
                  <MwAccordion class="custom-padding">
                    <MwAccordionItem label="Angiv eksporterede vandmængder">
                      <ImportExport
                        type="Eksporteret"
                        :vandanlaeg="vandanlaeg"
                        :vandanlaegVandData="localVandanlaegVandData"
                        :anlaegIndex="anlaegIndex"
                      >
                      </ImportExport>
                    </MwAccordionItem>
                  </MwAccordion>
                  <MwAccordion class="custom-padding">
                    <MwAccordionItem label="Angiv importerede vandmængder" :expanded="true">
                      <ImportExport
                        type="Importeret"
                        :vandanlaeg="vandanlaeg"
                        :vandanlaegVandData="localVandanlaegVandData"
                        :anlaegIndex="anlaegIndex"
                      >
                      </ImportExport>
                    </MwAccordionItem>
                  </MwAccordion>
                </div>
              </MwAccordionItem>
            </MwAccordion>

            <MwAccordion
              class="custom-padding"
              v-if="vandanlaeg.oppumpedeMaengderAnlaeg.fasteForsyning !== null && !optionalEkstraForsyning"
            >
              <MwAccordionItem label="Filterskylning og udpumpede vandmængder" :expanded="true">
                <div class="page-table-responsive mb-3">
                  <table class="table table--responsive-headers">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Mængde (m3)</th>
                        <!-- <th>Modtager antal</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(fastForsyning, fastForsyningIndex) in vandanlaeg.oppumpedeMaengderAnlaeg.fasteForsyning"
                        :key="fastForsyningIndex"
                      >
                        <td data-title="Type">
                          <div
                            v-if="
                              fastForsyning.forsyningsArtId === getForsyningsartIdByKode(forsyningsartKodeEnum.samletLeveretVandmaengde)
                            "
                          >
                            <span>Mængde afgang vandværk m3</span>
                            <p style="font-size: small">
                              *beregnet værdi udfra (grundvand minus eksporterede vandmængder plus importerede vandmængder minus filterskyl
                              minus vandværkets eget vandforbrug)
                            </p>
                          </div>
                          <span v-else>{{ fastForsyning.forsyningsArtNavn }}</span>
                        </td>
                        <td data-title="Mængde (m3)">
                          <div
                            v-if="
                              fastForsyning.forsyningsArtId === getForsyningsartIdByKode(forsyningsartKodeEnum.samletLeveretVandmaengde)
                            "
                          >
                            <MwInput
                              title="Indskriv mængde"
                              class="hide-label"
                              :label="'Mængde (m3) ' + fastForsyning.forsyningsArtNavn + ' ' + fastForsyningIndex"
                              :text.sync="fastForsyning.maengde"
                              :disabled="true"
                            ></MwInput>
                          </div>
                          <div v-else>
                            <MwInput
                              title="Indskriv mængde"
                              class="hide-label"
                              :label="'Mængde (m3) ' + fastForsyning.forsyningsArtNavn + ' ' + fastForsyningIndex"
                              :text.sync="fastForsyning.maengde"
                              :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                              :validations="
                                $v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.fasteForsyning.$each[
                                  fastForsyningIndex
                                ].maengde
                              "
                              @blur="sumMaengdeAfgangVandvaerk(vandanlaeg)"
                            ></MwInput>
                          </div>
                          <div
                            v-if="fastForsyning.forsyningsArtId === getForsyningsartIdByKode(forsyningsartKodeEnum.vandvaerketsEgetForbrug)"
                          >
                            <MwTextArea
                              :label="fastForsyning.forsyningsArtNavn + ' bemærkning'"
                              :text.sync="fastForsyning.bemaerkning"
                              :validations="
                                $v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.fasteForsyning.$each[
                                  fastForsyningIndex
                                ].bemaerkning
                              "
                              :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                            ></MwTextArea>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </MwAccordionItem>
            </MwAccordion>

            <MwAccordion
              class="custom-padding"
              v-if="(vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning !== null && optionalEkstraForsyning) || shouldBeOptional"
            >
              <MwAccordionItem
                :label="
                  vandanlaeg.erStorVandanlaeg
                    ? 'Solgte vandmængder fordelt på forsyningsart'
                    : 'Angiv hvordan vandmængde er fordelt på forsyningsarter'
                "
                :expanded="true"
              >
                <div class="mb-4 align-text-center" v-if="optionalEkstraForsyning">
                  <button
                    class="button button-secondary"
                    title="Tryk for at fjerne fordeling for forsyningsarter"
                    @click="removeOptional(anlaegIndex)"
                  >
                    <i class="icon icon-delete" />
                    <span style="vertical-align: super"> Fjern fordeling for forsyningsarter </span>
                  </button>
                </div>

                <div class="page-table-responsive">
                  <table class="table table--responsive-headers">
                    <thead>
                      <tr>
                        <th>Forsyningsart</th>
                        <th>Mængde (m3){{ optionalEkstraForsyning ? '' : '*' }}</th>
                        <th>Modtager antal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(forsyning, index) in vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning" :key="index">
                        <td data-title="Forsyningsart">{{ forsyning.forsyningsArtNavn }}</td>
                        <td data-title="Mængde (m3)">
                          <MwInput
                            class="hide-label"
                            :label="forsyning.forsyningsArtNavn + ' Mængde (m3)'"
                            :text.sync="forsyning.maengde"
                            :validations="
                              $v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.ekstraForsyning.$each[index].maengde
                            "
                            :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                            @blur="sumForsyningsarter(vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning)"
                          />
                        </td>
                        <td data-title="Modtager antal">
                          <MwInput
                            class="hide-label"
                            :label="forsyning.forsyningsArtNavn + ' Modtager antal'"
                            :text.sync="forsyning.modtagerAntal"
                            :validations="
                              $v.localVandanlaegVandData.$each[anlaegIndex].oppumpedeMaengderAnlaeg.ekstraForsyning.$each[index]
                                .modtagerAntal
                            "
                            :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td data-title="Forsyningsart" style="font-style: italic">{{ shouldBeOptional ? 'Solgt vandmængde' : 'I alt' }}</td>
                        <td data-title="Mængde (m3)">
                          <span v-if="sum">{{ sum }}</span
                          ><span v-else>-</span>
                        </td>
                        <td data-title="Modtager antal"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </MwAccordionItem>
            </MwAccordion>
          </template>
          <template v-else>
            <div class="align-text-center mt-5">
              <button
                class="button button-secondary"
                title="Tryk for at tilføje ekstra vandmængde fordeling på forsyningsarter"
                @click="addOptional(anlaegIndex)"
              >
                <i class="icon icon-add" />
                <span style="vertical-align: super"> Tilføj fordeling for forsyningsarter </span>
              </button>
            </div>
          </template>
        </MwAccordionItem>
      </MwAccordion>
    </div>

    <!-- PECT 30.11.2023: Felter vedr. boringer er nu flyttet ud fra Vandanlæg MwAccordion hierarki, -->
    <!-- så de vises selv om der ikke skal indberettes for vandanlæg -->
    <div>
      <h3 v-if="localBoringVandData.length > 0">Oppumpede mængder på boringer</h3>

      <MwAccordion v-for="(boring, boringIndex) in localBoringVandData" :key="boringIndex">
        <MwAccordionItem :label="'Boring med DGUnr = ' + boring.dguNr" :expanded="true">
          <MwAccordion class="custom-padding">
            <MwAccordionItem label="Angiv oppumpede mængder på boringen" :expanded="true">
              <div class="row" style="width: 100%">
                <div class="col-sm-12">Indberetning for året {{ requiredYear }}</div>
              </div>
              <div class="row" style="width: 100%">
                <div class="col-sm-6">
                  <MwSelect
                    label="Metode for måling"
                    :value.sync="boring.oppumpedeMaengder.jupiterIndvindingsmetodeId"
                    :options="lookups.indvindingsmetode"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.jupiterIndvindingsmetodeId"
                    :disabled="boring.oppumpedeMaengder.valideret"
                    @change="maalingChanged($event, boring.oppumpedeMaengder)"
                    :infoText="metodeForMaalingInfoTekst"
                  >
                  </MwSelect>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    ref="omregningsfaktor"
                    label="Omregningsfaktor"
                    title="Indskriv Omregningsfaktor"
                    :text.sync="boring.oppumpedeMaengder.omregningsfaktor"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.omregningsfaktor"
                    :disabled="isSkoen(boring.oppumpedeMaengder) || boring.oppumpedeMaengder.valideret"
                    @blur="calculateGrundvand(boring.oppumpedeMaengder)"
                    :infoText="omregningsfaktorInfoTekst"
                  >
                  </MwInput>
                </div>
              </div>
              <div class="row" width="100%" v-if="isVandmaalerMetode(boring.oppumpedeMaengder.jupiterIndvindingsmetodeId)">
                <div class="col-sm-6 mt-3">
                  <div class="form-check" v-for="vandmaalerSubMetodeOption in VandmaalerSubMetode.methods">
                    <input
                      :name="`${VandmaalerSubMetode.name}:${boring.indtagId}`"
                      class="form-check-input"
                      :id="vandmaalerSubMetodeOption.id"
                      type="radio"
                      :value="vandmaalerSubMetodeOption.id"
                      v-model="boring.oppumpedeMaengder.vandmaalerSubMetode"
                      @change="vandmaalerSubMetodeChanged($event, boring.oppumpedeMaengder)"
                    />
                    <label class="form-check-label">{{ vandmaalerSubMetodeOption.methodName }}</label>
                  </div>
                </div>
              </div>
              <div class="row" style="width: 100%">
                <div class="col-sm-6">
                  <MwInput
                    label="Målerstart"
                    title="Indskriv målerstart"
                    :text.sync="boring.oppumpedeMaengder.maalerstandStart"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.maalerstandStart"
                    :disabled="
                      isSkoen(boring.oppumpedeMaengder) ||
                      boring.oppumpedeMaengder.valideret ||
                      boring.oppumpedeMaengder.vandmaalerSubMetode === VandmaalerSubMetode.SummeretMaengde.id
                    "
                    @blur="calculateGrundvand(boring.oppumpedeMaengder)"
                  ></MwInput>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    label="Målerslut"
                    title="Indskriv målerslut"
                    :text.sync="boring.oppumpedeMaengder.maalerstandSlut"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.maalerstandSlut"
                    :disabled="
                      isSkoen(boring.oppumpedeMaengder) ||
                      boring.oppumpedeMaengder.valideret ||
                      boring.oppumpedeMaengder.vandmaalerSubMetode === VandmaalerSubMetode.SummeretMaengde.id
                    "
                    @blur="calculateGrundvand(boring.oppumpedeMaengder)"
                  ></MwInput>
                </div>
              </div>
              <div class="row mb-5" style="width: 100%">
                <div class="col-sm-6">
                  <MwInput
                    label="Grundvand (m3)"
                    title="Feltet er skrivebeskyttet"
                    :text.sync="boring.oppumpedeMaengder.grundvand"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.grundvand"
                    :disabled="
                      !(
                        isSkoen(boring.oppumpedeMaengder) ||
                        boring.oppumpedeMaengder.vandmaalerSubMetode === VandmaalerSubMetode.SummeretMaengde.id
                      ) || boring.oppumpedeMaengder.valideret
                    "
                  ></MwInput>
                </div>
                <div class="col-sm-6">
                  <MwInput
                    label="Sidste års mængde (m3)"
                    title="Feltet er skrivebeskyttet"
                    :text.sync="boring.sidsteAarsMaengde"
                    :disabled="true"
                  ></MwInput>
                </div>
              </div>
              <div class="row mb-5" style="width: 100%">
                <div class="col-12">
                  <MwTextArea
                    label="Bemærkning"
                    :text.sync="boring.oppumpedeMaengder.bemaerkning"
                    :validations="$v.localBoringVandData.$each[boringIndex].oppumpedeMaengder.bemaerkning"
                  />
                </div>
              </div>
            </MwAccordionItem>
          </MwAccordion>
        </MwAccordionItem>
      </MwAccordion>
    </div>

    <nav class="page-navigation d-print-none">
      <button class="button button-primary" @click="saveMaengde">Videre</button>
    </nav>
  </section>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';
import { HttpResponse } from '@/networking/interfaces';
import MwInput from '@/components/mwUtils/mwInputs/MwInput.vue';
import MwDateSelector from '@/components/mwUtils/mwInputs/MwDateSelector.vue';
import MwSelect from '@/components/mwUtils/mwInputs/MwSelect.vue';
import MwAddItemButton from '@/components/mwUtils/mwButtons/MwAddItemButton.vue';
import Indberetning, {
  VandanlaegVandData,
  BoringVandData,
  Forsyning,
  OppumpedeMaengderAnlaeg,
  OppumpedeMaengder,
  VandmaalerSubMetode,
} from '@/models/indberetning';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import MwAccordion from '@/components/mwUtils/accordions/MwAccordion.vue';
import ImportExport from '@/components/mwUtils/ImportExport.vue';
import MwAccordionItem from '@/components/mwUtils/accordions/MwAccordionItem.vue';
import MwTextArea from '@/components/mwUtils/mwInputs/MwTextArea.vue';
import WizardHelper from '@/mixins/WizardHelper';
import { decimalCustom, integerCustom, minValueDecimal } from '@/utils/validators';
import MwAlert from '@/components/mwUtils/alerts/MwAlert.vue';
import { MwMessageBus } from '@/views/MwMessageBus';
import { LookupState } from '@/store/statetypes';
import { Lookup } from '@/models/lookup';

enum ForsyningsartKode {
  vandvaerketsEgetForbrug = '96',
  samletLeveretVandmaengde = '98',
  filterskylning = '94',
}

@Component({
  components: { MwAlert, MwAccordionItem, MwAccordion, MwInput, MwDateSelector, MwSelect, MwAddItemButton, MwTextArea, ImportExport },
  validations: {
    localVandanlaegVandData: {
      $each: {
        oppumpedeMaengderAnlaeg: {
          startdato: {
            required,
          },
          slutdato: { required },
          maalerstandStart: {
            required: requiredIf(function (model) {
              //@ts-expect-error
              return !this.isSkoen(model) && model.vandmaalerSubMetode === VandmaalerSubMetode.MaalerAflaesning.id;
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          maalerstandSlut: {
            required: requiredIf(function (model) {
              return (
                //@ts-expect-error
                !this.isSkoen(model) && (model as OppumpedeMaengderAnlaeg).vandmaalerSubMetode === VandmaalerSubMetode.MaalerAflaesning.id
              );
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          jupiterIndvindingsmetodeId: { required },
          omregningsfaktor: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return !this.isSkoen(model);
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          bemaerkning: {
            required: requiredIf(function (model) {
              //@ts-expect-error
              return this.requiredBemaerkning(model);
            }),
          },
          grundvand: {
            required: requiredIf(function (model) {
              return (
                //@ts-expect-error
                this.isSkoen(model) || model.vandmaalerSubMetode == VandmaalerSubMetode.SummeretMaengde.id
              );
            }),
            decimalCustom,
            minValueDecimal: minValueDecimal(0),
          },
          fasteForsyning: {
            $each: {
              bemaerkning: {
                required: requiredIf(function (model) {
                  //@ts-expect-error
                  const isEgetForbrug = model.forsyningsArtId == this.getForsyningsartIdByKode(ForsyningsartKode.vandvaerketsEgetForbrug);
                  if (isEgetForbrug) {
                    return model.maengde !== null && model.maengde !== '';
                  }
                  return false;
                }),
                maxLength: maxLength(200),
              },
              maengde: {
                decimalCustom,
              },
            },
          },
          ekstraForsyning: {
            $each: {
              maengde: {
                required: requiredIf(function () {
                  //@ts-ignore
                  return this.optionalEkstraForsyning == false;
                }),
                integerCustom,
                minValueDecimal: minValueDecimal(0),
              },
              modtagerAntal: {
                integerCustom,
                minValueDecimal: minValueDecimal(0),
              },
            },
          },
        },
      },
    },
    localBoringVandData: {
      $each: {
        oppumpedeMaengder: {
          startdato: {
            required,
          },
          slutdato: { required },
          maalerstandStart: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return !this.isSkoen(model) && (model as OppumpedeMaengder).vandmaalerSubMetode === VandmaalerSubMetode.MaalerAflaesning.id;
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          maalerstandSlut: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return !this.isSkoen(model) && (model as OppumpedeMaengder).vandmaalerSubMetode === VandmaalerSubMetode.MaalerAflaesning.id;
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          jupiterIndvindingsmetodeId: { required },
          omregningsfaktor: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return !this.isSkoen(model);
            }),
            minValueDecimal: minValueDecimal(0),
            decimalCustom,
          },
          bemaerkning: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return this.requiredBemaerkning(model);
            }),
          },
          grundvand: {
            required: requiredIf(function (model) {
              //@ts-ignore
              return this.isSkoen(model) || model.vandmaalerSubMetode == VandmaalerSubMetode.SummeretMaengde.id;
            }),
            decimalCustom,
            minValueDecimal: minValueDecimal(0),
          },
        },
      },
    },
  },
  data() {
    return new (class {
      localVandanlaegVandData: VandanlaegVandData[] = [];
    })();
  },
})
export default class Maengder extends Mixins(WizardHelper) {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;

  @Getter('INDBERETNINGLOOKUPS', { namespace: STORE_NS_LOOKUPS })
  lookups!: LookupState;

  @Action('SUM_FORSYNINGSARTER', { namespace: STORE_NS_INDBERETNING })
  getSumForsyningsarter!: (forsyningsarter: Forsyning[]) => Promise<HttpResponse>;

  @Action('BEREGN_MAENGDEAFGANGVANDVAERK', { namespace: STORE_NS_INDBERETNING })
  getBeregnetMaengdeAfgangVandvaerk!: (vandanlaegVandData: VandanlaegVandData) => Promise<HttpResponse>;

  requiredYear!: number;

  localVandanlaegVandData: VandanlaegVandData[];
  localBoringVandData: BoringVandData[];
  optionalEkstraForsyning: boolean = false;
  shouldBeOptional: boolean = false;
  optionalObject: Forsyning[];
  forsyningsartKodeEnum = ForsyningsartKode;

  metodeForMaalingInfoTekst: string =
    'Her angives hvilken metode som er benyttet til måling af de oppumpede vandmængder. Metoden bruges til at beregne mængden af oppumpet grundvand. Typisk vil metoden være vandmåler/flowmåler eller skøn. Hvis du vil angive en mængde direkte i kubikmeter, skal du angive metoden skøn.';
  //omregningsfaktorInfoTekst: string = 'Hvis du har valgt metoden vandmåler/flowmåler/timetæller er omregningsfaktoren normalt = 1';
  omregningsfaktorInfoTekst: string =
    'Ved en vandmåler, der måler m³ er omregningsfaktoren = 1<br/>Ved en timetæller er omregningsfaktoren = den anvendte pumpeydelse = antal m³/time<br/>Ved en elmåler er omregningsfaktoren = antal m³/kWh';
  sum: number = 0;
  maengdeSum: number = 0;

  //Needs to be defined inside the class scope otherwise the template won't recognize it
  VandmaalerSubMetode = VandmaalerSubMetode;

  constructor() {
    super();
    this.localVandanlaegVandData = [];
    this.localBoringVandData = [];
    this.optionalObject = [];
  }

  async created() {
    this.localVandanlaegVandData = this.indberetning.vandanlaegVandData;
    this.localBoringVandData = this.indberetning.boringVandData; //this.indberetning.oppumpedeMaengderAnlaeg;
    this.requiredYear = this.indberetning.aar;
    this.localVandanlaegVandData.forEach((vandanlaeg, index) => {
      if (
        vandanlaeg &&
        vandanlaeg.oppumpedeMaengderAnlaeg &&
        vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning &&
        vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning.length > 0
      ) {
        vandanlaeg.oppumpedeMaengderAnlaeg.fasteForsyning.forEach(
          f => (f.forsyningsArtKode = this.getForsyningsartKodeById(f.forsyningsArtId))
        );
        this.sumMaengdeAfgangVandvaerk(vandanlaeg);
        this.sumForsyningsarter(vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning);
      }

      // Set default option if the right method is selected but no option
      if (
        this.isVandmaalerMetode(vandanlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId) &&
        vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode === undefined
      ) {
        vandanlaeg.oppumpedeMaengderAnlaeg.vandmaalerSubMetode = VandmaalerSubMetode.MaalerAflaesning.id;
      }

      if (vandanlaeg.vandanlaegKunOppumpetMaengder) {
        vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning.forEach(item => {
          this.maengdeSum += Number(item.maengde);
        });
        this.optionalObject = vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning.slice(0);
        vandanlaeg.oppumpedeMaengderAnlaeg.fasteForsyning = [];

        if (this.maengdeSum === 0) {
          vandanlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning = [];
        } else {
          this.addOptional(index);
          this.maengdeSum = 0;
        }
      } else {
        this.shouldBeOptional = !this.shouldBeOptional;
      }

      // for vandanlæg:
      // hvis målemetode tidligere er angivet og gemt som kladde, men nu ikke længere findes på liste over målemetoder, så blank valg
      // dette fremtvinger korrekt validering
      let maaleMetodeFindes = false;
      this.lookups.indvindingsmetode.forEach(item => {
        if (item.id == vandanlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId) {
          maaleMetodeFindes = true;
        }
      });

      if (maaleMetodeFindes == false) {
        vandanlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId = '';
      }
    });

    // For boringer:
    // hvis målemetode tidligere er angivet og gemt som kladde, men nu ikke længere findes på liste over målemetoder, så blank valg
    // dette fremtvinger korrekt validering
    this.localBoringVandData.forEach((boring, index) => {
      let maaleMetodeFindes = false;
      this.lookups.indvindingsmetode.forEach(item => {
        if (item.id == boring.oppumpedeMaengder.jupiterIndvindingsmetodeId) {
          maaleMetodeFindes = true;
        }
      });

      if (maaleMetodeFindes == false) {
        boring.oppumpedeMaengder.jupiterIndvindingsmetodeId = '';
      }
    });
  }

  collapseItem: boolean = false;
  isSkoen(model: any) {
    if (model.jupiterIndvindingsmetodeId === null) {
      return false;
    }

    const indvindingsmetodeObj = this.lookups.indvindingsmetode.find((metode: any) => {
      return metode.id === model.jupiterIndvindingsmetodeId.toString();
    });

    return indvindingsmetodeObj && indvindingsmetodeObj.navn.toLowerCase().includes('skøn');
  }

  isVandmaalerMetode(jupiterIndvindingsmetodeId: string): boolean {
    const extractionMethod = this.lookups.indvindingsmetode.find((metode: any) => {
      return metode.id === jupiterIndvindingsmetodeId;
    });
    //ExtractionMethod = "Vandmåler"
    return extractionMethod != undefined && extractionMethod != null && (extractionMethod as Lookup).kode === '2';
  }

  getForsyningsartIdByKode(kode: string): string | undefined {
    const forsyningsart = this.lookups.jupiterForsyningsart.find(x => x.kode == kode);
    if (forsyningsart != undefined && forsyningsart != null) {
      return forsyningsart.id;
    }

    return undefined;
  }

  getForsyningsartKodeById(id: string): string | undefined {
    const forsyningsart = this.lookups.jupiterForsyningsart.find(x => x.id == id);
    if (forsyningsart != undefined && forsyningsart != null) {
      return forsyningsart.kode;
    }
    return undefined;
  }

  requiredBemaerkning(model: any) {
    //ikke påkrævet hvis der ikke er valgt en metode for måling
    if (model.jupiterIndvindingsmetodeId === null) {
      return false;
    }

    //den nuværende valgte metode
    const indvindingsmetodeObj = this.lookups.indvindingsmetode.find((metode: any) => {
      return metode.id === model.jupiterIndvindingsmetodeId.toString();
    });

    //påkrævet hvis andet(99) el. oppumpede vandmængde(6) er valgt
    return indvindingsmetodeObj && (indvindingsmetodeObj.kode === '6' || indvindingsmetodeObj.kode === '99');
  }

  calculateGrundvand(model: any, index?: number) {
    // If selected submethod is chosen on anlaeg, "Grundvand" should not be calculated
    if (model.vandmaalerSubMetode == VandmaalerSubMetode.SummeretMaengde.id) {
      return model.grundvand;
    }

    if (model.maalerstandStart === '' || model.maalerstandStart == null) {
      model.grundvand = '';
      return model.grundvand;
    }

    if (model.maalerstandSlut === '' || model.maalerstandSlut == null) {
      model.grundvand = '';
      return model.grundvand;
    }

    if (model.omregningsfaktor === '' || model.omregningsfaktor == null) {
      model.grundvand = '';
      return model.grundvand;
    }

    const start: number = parseFloat(this.convertCommaToDotAndRemoveSeperator(model.maalerstandStart));
    const slut: number = parseFloat(this.convertCommaToDotAndRemoveSeperator(model.maalerstandSlut));
    const omregning: number = parseFloat(this.convertCommaToDotAndRemoveSeperator(model.omregningsfaktor));

    model.grundvand = this.calculatedResultAsStringWithXDecimals((slut - start) * omregning, 2);
    if (index !== undefined) {
      this.sumMaengdeAfgangVandvaerk(this.localVandanlaegVandData[index]);
    }
    return model.grundvand;
  }

  convertCommaToDotAndRemoveSeperator = (value: string) => {
    if (value === null || value === undefined) {
      return '0';
    } else {
      const valueFormatFix = value.split('.').join('');
      const valueFormatFix2 = valueFormatFix.split(',').join('.');
      return valueFormatFix2;
    }
  };

  hasValidatedItems() {
    const hasValidatedVandanlaeg = this.localVandanlaegVandData.some((anlaeg: any) => anlaeg.oppumpedeMaengderAnlaeg.valideret);
    const hasValidatedBoring = this.localBoringVandData.some((boring: any) => boring.oppumpedeMaengder.valideret);
    return hasValidatedVandanlaeg || hasValidatedBoring;
  }

  hasVandanlaeg() {
    const hasVandanlaeg = this.indberetning.vandanlaegVandData.length > 0;
    return hasVandanlaeg;
  }

  vandmaalerSubMetodeChanged(event: Event, model: OppumpedeMaengderAnlaeg | OppumpedeMaengder) {
    const value = +(event.target as HTMLInputElement).value;
    //TODO only reset validation for maalerstandStart, maalerstandSlut
    //TODO the current solution reset validations for all element
    MwMessageBus.$emit('resetValidation');

    model.vandmaalerSubMetode = value;

    switch (value) {
      case VandmaalerSubMetode.SummeretMaengde.id: {
        model.maalerstandSlut = null;
        model.maalerstandStart = null;
        model.vandmaalerSubMetode = value;
      }
      case VandmaalerSubMetode.MaalerAflaesning.id:
      default: {
        model.grundvand = null;
        this.calculateGrundvand(model);
        break;
      }
    }
  }

  maalingChanged(event: any | string, model: any) {
    const value = event.target.value;

    const indvindingsmetodeObj = this.lookups.indvindingsmetode.find((metode: any) => {
      return metode.id === value;
    });

    //TODO only reset validation for maalerstandStart, maalerstandSlut and omregningsfaktor
    //TODO the current solution reset validations for all element
    MwMessageBus.$emit('resetValidation');

    //Extractionmethod = "Vandmåler", default to old selection
    if (this.isVandmaalerMetode(value)) {
      if (model.vandmaalerSubMetode == undefined) {
        model.vandmaalerSubMetode = VandmaalerSubMetode.MaalerAflaesning.id;
      }
      model.maalerstandSlut = null;
      model.maalerstandStart = null;
    } else {
      model.vandmaalerSubMetode = undefined;
    }

    if (indvindingsmetodeObj && indvindingsmetodeObj.navn.toLowerCase().includes('skøn')) {
      model.maalerstandStart = null;
      model.maalerstandSlut = null;
      model.omregningsfaktor = null;
    } else {
      if (!model.omregningsfaktor) {
        this.calculateGrundvand(model);
      }
    }
  }

  saveMaengde() {
    if (this.validate()) {
      this.goToRoute('pejlinger');

      window.scrollTo(0, 0);
    } else {
      document.querySelectorAll('.accordion-button').forEach(element => element.setAttribute('aria-expanded', 'true'));
      document.querySelectorAll('.accordion-content').forEach(element => element.setAttribute('aria-hidden', 'false'));
      window.scrollTo(0, 0);
    }
  }

  sumForsyningsarter(forsyningsarter: Forsyning[]) {
    this.getSumForsyningsarter(forsyningsarter).then((resp: HttpResponse) => {
      this.sum = resp.data;
    });
  }

  sumMaengdeAfgangVandvaerk(model: VandanlaegVandData) {
    this.getBeregnetMaengdeAfgangVandvaerk(model).then((resp: HttpResponse) => {
      const maengdeafgangvandvaerk = model.oppumpedeMaengderAnlaeg.fasteForsyning.find(
        (x: Forsyning) => x.forsyningsArtKode === this.forsyningsartKodeEnum.samletLeveretVandmaengde
      );
      if (maengdeafgangvandvaerk !== undefined && maengdeafgangvandvaerk !== null) {
        maengdeafgangvandvaerk.maengde = resp.data.samletLeveretMaengde;
      }
    });
  }

  addOptional(anlaegIndex: any) {
    this.optionalEkstraForsyning = !this.optionalEkstraForsyning;
    this.localVandanlaegVandData[anlaegIndex].vandanlaegKunOppumpetMaengder =
      !this.localVandanlaegVandData[anlaegIndex].vandanlaegKunOppumpetMaengder;
    this.localVandanlaegVandData[anlaegIndex].oppumpedeMaengderAnlaeg.ekstraForsyning = this.optionalObject;
  }

  removeOptional(anlaegIndex: any) {
    this.optionalEkstraForsyning = !this.optionalEkstraForsyning;
    this.localVandanlaegVandData[anlaegIndex].vandanlaegKunOppumpetMaengder =
      !this.localVandanlaegVandData[anlaegIndex].vandanlaegKunOppumpetMaengder;
    this.localVandanlaegVandData[anlaegIndex].oppumpedeMaengderAnlaeg.ekstraForsyning = [];
  }

  calculatedResultAsStringWithXDecimals(result: number, decimals: number) {
    return (Math.round(result * 100) / 100).toFixed(decimals).toString().replace('.', ',');
  }
}
</script>

<style>
.custom-padding .accordion-content {
  padding-right: 0;
  padding-left: 0;
}

.hide-label label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.function-link {
  text-decoration: underline;
  cursor: pointer;
}

.subcontainer {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

input[type='radio'] {
  margin: 5px;
}
</style>
