<template>
  <div class="" role="main">
    <h1>Velkommen</h1>
    <p v-if="linkIdStatus === 'INVALID'" style="color:red;">Linket er ugyldigt eller udløbet.</p>
    <p>
      For at gennemføre din indberetning skal du anvende det link du har modtaget fra din kommune. Du kan enten klikke på linket eller
      kopiere det direkte ind i din browser.
    </p>
    <template v-if="!isProduction()">
      <hr />
      <p>Production mode:{{ isProduction() }}</p>
      <MwInput :text.sync="linkId" label="Kode" />
      <nav class="page-navigation d-print-none">
        <router-link
          class="button button-primary"
          :to="{ path: `/indberetning/${this.linkId}/home` }"
          tag="button"
          :disabled="linkId === ''"
          onabort="setLinkIdStatus('INVALID')"
          >Videre</router-link
        >
      </nav>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MwInput from '@/components/mwUtils/mwInputs/MwInput.vue';
import { Getter, Mutation } from 'vuex-class';
import { STORE_NS_INDBERETNING } from '@/constants';
import WizardItem from '@/models/wizard';
// import router

@Component({
  components: { MwInput }
})
export default class Indberetning extends Vue {
  @Getter('LINKID_STATUS')
  linkIdStatus!: string;

  @Mutation('SET_LINKID_STATUS')
  setLinkIdStatus!: (status: string) => void;

  linkId: string;

  constructor() {
    super();

    this.linkId = '';
  }

  isProduction() {
    const env = process.env.NODE_ENV;
    return env === 'production';
  }
}
</script>
