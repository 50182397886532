import { GetterTree } from 'vuex';
import { RootState, IndberetningState } from '@/store/statetypes';
import { LookupState } from '@/store/statetypes';

export const getters: GetterTree<LookupState, RootState> = {
  INDBERETNINGLOOKUPS(state: LookupState): LookupState {
    return {
      indvindingsmetode: state.indvindingsmetode,
      jupiterPejleSituation: state.jupiterPejleSituation,
      jupiterPejleMetode: state.jupiterPejleMetode,
      jupiterReferencepunkt: state.jupiterReferencepunkt,
      jupiterPejleEkstremer: state.jupiterPejleEkstremer,
      jupiterForsyningsart: state.jupiterForsyningsart
    };
  }
};
