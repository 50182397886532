import { BoringPejleData} from '@/models/indberetning';
import { helpers } from 'vuelidate/lib/validators';

export const convertCommaToDotAndRemoveSeperator = (value: string) => {
  if (value) {
    let transformedValue = value.toString().replace(/,/g, '.');

    if (transformedValue.includes('.')) {
      const lastDotIndex = transformedValue.lastIndexOf('.');
      const stringBeforeLastDot = transformedValue.substring(0, lastDotIndex).replace(/\./g, '');
      transformedValue = stringBeforeLastDot + transformedValue.substring(lastDotIndex);
    }

    return transformedValue;
  }
  return value;
};

export const integerCustom = (value: any) => {
  if (value === null || value === undefined || value === '' || value === 0 || value === '0' || value === '0,00') {
    return true;
  }

  value = value.toString();

  if (value.includes(',')) {
    return false;
  }

  if (!value.includes('.')) {
    return new RegExp(/^-?\d+?$/).test(value);
  }

  return new RegExp(/^-?(\d+(?:[\.]\d{3})+?)$/).test(value);
};

/**
 * @description Supports comma seperator
 * @param value
 */
export const decimalCustom = (value: any) => {
  if (value === null || value === undefined || value === '' || value === 0 || value === '0' || value === '0,00') {
    return true;
  }
  return new RegExp(/^-?(?:(?!0+\,00)(?=.+(\,|$))(?!0(?!\,))\d+(\.\d{3})*(\,\d{1,16})?)?$/).test(value); //Olli var her
};

/**
 * @description Supports comma seperator
 * @param minValue
 */
export const minValueDecimal = (minValue: any) =>
  helpers.withParams({ type: 'minValueDecimal', min: minValue }, (value: any) => {
    const transformedValue = convertCommaToDotAndRemoveSeperator(value);

    return transformedValue >= minValue;
  });

/**
 * @description Supports comma seperator
 * @param maxValue
 */
export const maxValueDecimal = (maxValue: any) =>
  helpers.withParams({ type: 'maxValueDecimal', min: maxValue }, (value: any) => {
    const transformedValue = convertCommaToDotAndRemoveSeperator(value);
    return transformedValue <= maxValue;
  });

/**
 * @param {number} [length=2]
 */
export const maxDecimalLengthCustom = (length: number = 2) =>
  //@ts-ignore
  helpers.withParams({ type: 'maxDecimalLengthCustom', max: length }, (value: any) => {
    //TODO brug regexp

    const strValue = value.toString();
    if (strValue.includes('.') || strValue.includes(',')) {
      if (strValue.includes(',')) {
        const decimals = strValue.split(',')[1];
        return decimals.length <= length && decimals.length > 0;
      } else if (strValue.includes('.')) {
        const decimals = strValue.split('.')[1];
        return decimals.length <= length && decimals.length > 0;
      }
    } else {
      return true;
    }
  });

export const kote = (val: string, parentVm: BoringPejleData) => {
  if (val === '20200514-1540-0097-0003-570071406661') {
    //Terræn
    if (!parentVm.terraenkote) {
      return false;
    }
  } else if (val === '20200514-1540-0097-0002-570071143796') {
    //Pejlepunkt
    if (!parentVm.pejlepunktKote) {
      return false;
    }
  }
  return true;
};
