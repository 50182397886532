<template>
  <div>
    <Header />
    <div class="container page-container pt-4 pt-lg-6">
      <transition name="fade" mode="out-in">
        <div v-if="isInternetExplorer()">
          Siden er ikke understøttet af Internet Explorer.
          <template v-if="isInternetExplorerAndIsWindows10">
            <br/>
            <a :href="'microsoft-edge:' + this.currentURL">Klik her for at åbne siden i en anden browser</a>
          </template>
        </div>
        <keep-alive v-else>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Watch, Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header/Header.vue';
import Footer from '@/components/Footer.vue';
import { STORE_NS_INDBERETNING } from '@/constants';
import Indberetning from '@/models/indberetning';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    Header,
    Footer
  }
})
export default class App extends Vue {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;

  isInternetExplorer() {
    return (document as any).documentMode === 11;
  }

  isInternetExplorerAndIsWindows10() {
    return this.isInternetExplorer() && navigator.userAgent.indexOf('Windows NT 10.0') > -1;
  }

  get currentURL() {
    return document.URL;
  }
}
</script>

<style lang="less">
@import './css/routingTransitions.less';
@import '../node_modules/dkfds/dist/css/dkfds.css';
@import '../node_modules/dkfds/dist/css/dkfds-virkdk.css';

.app,
.main-content,
.router-view {
  transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
/* Fixes Edge bug, where list-style-type:none is ignored if it's set after li has been hidden. */
ul {
  list-style-type: none;
}
</style>
