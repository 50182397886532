<template>
  <ul class="accordion accordion-multiselectable">
    <slot></slot>
  </ul>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { Accordion } from 'dkfds';

@Component
export default class MwAccordion extends Vue {
  @Prop({ default: '' }) accordionTitle!: string;
  @Prop({ default: '' }) iconText!: string;
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) label!: any;

  @Prop({ default: null }) validations!: vuelidate.Params;

  mounted() {
    this.$nextTick(() => {
      const accordion = new Accordion(this.$el);
    });
    //TODO DO NOT USE setTimeout
    // setTimeout(() => {
    //   const accordion = new Accordion(this.$el);
    // }, 1000);
  }
}
</script>

<style scoped></style>
