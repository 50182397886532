import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { indberetningState } from './modules/indberetning';
import { lookupState } from './modules/lookups';

import { RootState } from './statetypes';

Vue.use(Vuex);
const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
    wizard: [],
    linkId: '',
    linkIdStatus: '',
    anchor: ''
  },
  modules: {
    [STORE_NS_INDBERETNING]: indberetningState,
    [STORE_NS_LOOKUPS]: lookupState
  },
  actions: {
    clearstates() {
      // localStorage.removeItem('usertoken');
      this.dispatch(`${STORE_NS_INDBERETNING}/CLEARSTATE`);
    }
  },
  getters: {
    LINKID: (state: RootState) => {
      return state.linkId;
    },
    LINKID_STATUS: (state: RootState) => {
      return state.linkIdStatus;
    },
    WIZARD: (state: RootState) => {
      return state.wizard;
    },

    GET_ANCHOR: (state: RootState) => {
      return state.anchor;
    }
  },
  mutations: {
    SET_WIZARD(state: RootState, wizard) {
      state.wizard = wizard;
    },
    SET_LINKID(state: RootState, linkId) {
      state.linkId = linkId;
    },
    SET_LINKID_STATUS(state: RootState, linkId) {
      state.linkIdStatus = linkId;
    },

    SET_ANCHOR(state: RootState, anchor: string) {
      state.anchor = anchor;
    }
  }
};

const store = new Vuex.Store<RootState>(storeOptions);

export default store;
