<template>
  <section>
    <div class="col-12">
      <a href="javascript:void(0);" @click="goToRoute('pejlinger', true)" class="back-link d-none d-lg-inline-block " type="button"
        >Tilbage</a
      >
    </div>
    <MwAlert type="warning" heading="Der er problemer" v-if="checkForErrorsOrInvalid()">
      <p>
        Der mangler informationer på forrige trin hvorfor opsummeringssiden er mangelfuld. Du har derfor ikke mulighed for at indberette.
      </p>
      <p>
        Gå tilbage til forrige trin og udfyld informationer for at indberette.
      </p>
    </MwAlert>
    <h1>Opsummering</h1>

    <p class="font-lead">Her kan du kontrollere, om oplysningerne er korrekte.</p>

    <OpsummeringKvittering />

    <nav class="page-navigation d-print-none">
      <!-- TODO disable button based on variable hasErrorsOrIsInvalid -->
      <button :disabled="checkForErrorsOrInvalid()" class="button button-primary" @click="saveIndberetningInfo">
        Indberet
      </button>
    </nav>
  </section>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';
import Todo from '@/models/todo';

import { HttpResponse } from '@/networking/interfaces';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';

import MwOpsummering from '@/components/mwUtils/opsummering/MwOpsummering.vue';
import MwOpsummeringItem from '@/components/mwUtils/opsummering/MwOpsummeringItem.vue';
import Indberetning from '@/models/indberetning';
import WizardHelper from '@/mixins/WizardHelper';
import OpsummeringKvittering from '@/views/OpsummeringKvittering.vue';
import MwAlert from '@/components/mwUtils/alerts/MwAlert.vue';
import WizardItem from '@/models/wizard';

@Component({
  components: { MwOpsummering, MwOpsummeringItem, OpsummeringKvittering, MwAlert }
})
export default class Opsummering extends Mixins(WizardHelper) {
  @Action('UPDATE_INDBERETNINGINFO', { namespace: STORE_NS_INDBERETNING })
  gemIndberetningInfo!: (linkId: string) => Promise<HttpResponse>;

  @Action('RESET_INDBERETNING_MIDLERTIDIGT_GEMT', { namespace: STORE_NS_INDBERETNING })
  nulstilIndberetningMidlertidigtGemt!: (linkId: string) => Promise<HttpResponse>;

  @Getter('WIZARD')
  wizardItems!: WizardItem[];

  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;

  @Getter('LINKID')
  linkId!: string;

  @Getter('INDBERETNINGLOOKUPS', { namespace: STORE_NS_LOOKUPS })
  lookups!: any;

  // hasErrorsOrIsInvalid = true;

  checkForErrorsOrInvalid() {
    const validCheck = this.wizardItems.some(item => {
      const isOpsummeringPage = item.route === 'opsummering';
      const isValid = item.valid;
      const hasErrors = item.hasErrors;

      if (isOpsummeringPage) {
        return false;
      }

      return !isValid || hasErrors;
    });
    return validCheck;
  }

  validate(): boolean {
    return true;
  }

  async saveIndberetningInfo() {
    if (this.validate()) {
      const { status, data } = await this.gemIndberetningInfo(this.linkId).catch(errorObj => {
        const response = errorObj.response;
        return { status: response.status, data: response.data };
      });

      if (status === 200) {
        this.$router.push({ name: 'kvittering' });
      } else {
        alert('Kunne ikke gennemføre indberetningen. Der opstod en fejl.');
      }
    }

    await this.nulstilIndberetningMidlertidigtGemt(this.linkId);

    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>
.border-none {
  border: none !important;
  width: max-content;
}
</style>
