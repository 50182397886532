<template>
  <li>
    <button class="accordion-button" :aria-expanded="expanded" :aria-controls="tempId" :id="accordionItemId">
      {{ label }}

      <!-- <template v-else>{{ label }}</template> -->
    </button>
    <div :id="tempId" aria-hidden="false" class="accordion-content">
      <slot></slot>
    </div>
  </li>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { Guid } from 'guid-typescript';

@Component
export default class MwAccordion extends Vue {
  @Prop({ default: null }) id!: string;

  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) label!: any;
  @Prop({ default: false }) expanded!: boolean;

  @Prop({ default: null }) validations!: vuelidate.Params;

  tempId: string = 'accordionItem' + Guid.create().toString();
  accordionItemId: string = 'accordionItem' + this.id;
}
</script>
<style scoped></style>
