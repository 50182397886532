import { MutationTree } from 'vuex';
import { IndberetningState } from '@/store/statetypes';
import { getDefaultState } from '.';
import Indberetning from '@/models/indberetning';

export const mutations: MutationTree<IndberetningState> = {
  SET_INDBERETNING(state: IndberetningState, indberetning: Indberetning) {
    state.indberetning = indberetning; //Object.assign(new Indberetning(), indberetning);
  },

  CLEARSTATE(state: IndberetningState) {
    Object.assign(state, getDefaultState());
  }
};
