import axios, { Axios, AxiosRequestConfig, responseEncoding } from 'axios';

import { HttpOptions, HttpCancelToken, HttpResponse, HttpFileResponse } from './interfaces';
import { parseFilenameFromContentDisposition } from '@/networking/httputils';

// TODO: Shouldn't export the base URL as all interaction with the API must happen through the http abstraction layer.
//@ts-ignore The variable MW_API_URL is defined in vue.config.js
export let API_URL = MW_API_URL;

const standardClient = axios.create({
  baseURL: API_URL,
});

function newHttpOptions(): HttpOptions {
  return {
    cancelToken: new HttpCancelToken(),
  };
}

export async function get(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.get(url, { cancelToken: options.cancelToken.source.token })
      : await standardClient.get(url);
  return { status, data };
}

export async function post(url: string, payload: any, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.post(url, payload, { cancelToken: options.cancelToken.source.token })
      : await standardClient.post(url, payload);
  return { status, data };
}

export async function put(url: string, payload: any, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.put(url, payload, { cancelToken: options.cancelToken.source.token })
      : await standardClient.put(url, payload);
  return { status, data };
}

export async function del(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.delete(url, { cancelToken: options.cancelToken.source.token })
      : await standardClient.delete(url);
  return { status, data };
}

export async function download(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpFileResponse> {
  const config: AxiosRequestConfig = {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream',
    },
  };

  const { status, data, headers } =
    options.cancelToken != null
      ? await standardClient.get(url, { ...config, cancelToken: options.cancelToken.source.token })
      : await standardClient.get(url, config);

  const filename = parseFilenameFromContentDisposition(headers['content-disposition']);
  const mimeType = headers['content-type'] ?? 'application/octet-stream';

  return { status, data, filename, mimeType };
}

export default standardClient;
