<template>
  <div :class="'alert alert--show-icon alert-' + type" role="alert" aria-atomic="true" :aria-label="type">
    <div class="alert-body">
      <p class="alert-heading">{{ heading }}</p>
      <slot class="alert-text"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';

@Component
export default class MwAlert extends Vue {
  @Prop({ default: '' }) heading!: string;
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) type!: string;
}
</script>

<style scoped></style>
