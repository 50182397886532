<template>
  <form class="form  input-width-m">
    <div class="form-group  " :class="{ 'form-error': validationError !== '' }">
      <label class="form-label " :for="selectElementId" v-show="label !== ''">
        <template v-if="isRequired">{{ label }} *</template>
        <template v-else>{{ label }}</template>
      </label>
      <template v-if="infoText.length > 0">
        <i
          :id="infoElementId"
          style="cursor:pointer;vertical-align: bottom;"
          class="js-tooltip icon icon-help"
          data-tooltip-trigger="click"
          :data-tooltip="infoText"
        />
      </template>

      <span class="form-error-message" id="example-name-error" v-if="this.validationError !== ''">
        <span class="sr-only">Fejl:</span> {{ this.validationError }}
      </span>
      <select
        class=" form-select "
        @change="change"
        name="options"
        :disabled="disabled"
        :id="selectElementId"
        @blur="blur"
        v-model="localValue"
        autocomplete="off"
        :required="isRequired"
        :aria-required="areaRequired"
        :optional="optional"
      >
        <option :value="null" hidden v-if="!optional">Vælg</option>
        <option :value="option.id" v-for="(option, index) in options" :key="index">{{ option.navn }}</option>
      </select>
    </div>
  </form>
</template>

<script lang="ts">
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { Guid } from 'guid-typescript';
import { MwMessageBus } from '@/views/MwMessageBus';
import { Tooltip } from 'dkfds';

@Component
export default class MwSelect extends Vue {
  @Prop({ default: 'labelText' }) label!: string;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) optional!: boolean;
  @Prop({}) options!: [];
  @Prop() value!: string;
  @Prop({ default: '' }) infoText!: string;

  selectElementId: string = 'ddlElement' + Guid.create().toString();
  infoElementId: string = 'info' + Guid.create().toString();
  validationError: string = '';
  localValue: string = '';
  mounted() {
    this.localValue = this.value;
    if (this.infoText.length > 0) {
      const tooltip = new Tooltip(document.getElementById(this.infoElementId));
    }
  }
  created() {
    MwMessageBus.$on('validate', () => {
      this.validateInput();
      // this.validateScrollTo();
    });
  }

  change(event: any) {
    this.$emit('update:value', event.target.value);
    this.localValue = event.target.value;

    this.$emit('change', event);
    // if (this.validationError) {
    this.validateInput();
    // }
  }
  blur() {
    // this.$emit('blur');
    this.validateInput();
  }
  validateInput() {
    this.validationError = '';
    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = 'Feltet er påkrævet';
      }
      if (!this.validationError && this.validations.minLength === false) {
        this.validationError = ''; //this.$t.errorMinLength(this.validations.$params.minLength.min);
      }
      if (!this.validationError && this.validations.maxLength === false) {
        this.validationError = ''; //this.$t.errorMaxLength(this.validations.$params.maxLength.max);
      }
      if (!this.validationError && this.validations.decimal === false) {
        this.validationError = ''; //this.$t.errorDecimal;
      }
      if (!this.validationError && this.validations.minValue === false) {
        this.validationError = ''; //this.$t.errorMinValue(this.validations.$params.minValue.min);
      }
      if (!this.validationError && this.validations.maxValue === false) {
        this.validationError = ''; //this.$t.errorMaxValue(this.validations.$params.maxValue.max);
      }
      if (!this.validationError && this.validations.numeric === false) {
        this.validationError = ''; //this.$t.errorNumeric;
      }
      if (!this.validationError && this.validations.email === false) {
        this.validationError = ''; //this.$t.errorEmail;
      }
      if (!this.validationError && this.validations.url === false) {
        this.validationError = ''; //this.$t.errorUrl;
      }
      if (!this.validationError && this.validations.minValueDecimal === false) {
        this.validationError = ''; //this.$t.errorMinValue(this.validations.$params.minValueDecimal.min);
      }
      if (!this.validationError && this.validations.kote === false) {
        this.validationError = this.$t.errorKote;
      }
      if (!this.validationError && this.validations.maxValueDecimal === false) {
        this.validationError = ''; //this.$t.errorMaxValue(this.validations.$params.maxValueDecimal.min);
      }
    }
  }

  get isRequired() {
    return this.validations != null && this.validations.$params != null && this.validations.$params.required;
  }

  get areaRequired() {
    if (this.isRequired) {
      return true;
    }
  }
}
</script>

<style scoped>
.form-group:first-child {
  margin-top: 24px;
}
</style>
