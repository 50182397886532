<template>
  <div class="overflow-menu  overflow-menu--open-right overflow-menu--lg-no-responsive d-print-none ">
    <button class="button-overflow-menu js-dropdown " :data-js-target="id" aria-haspopup="true" aria-expanded="false">
      Trin {{ currentStep() + 1 }} af {{ items.length }}
      <i class="icon icon-menu-down"></i>
    </button>
    <div class="overflow-menu-inner" :id="id" aria-hidden="true">
      <nav>
        <ul class="sidenav-list" role="menu">
          <li role="none" v-for="(item, index) in items" :key="index" :class="{ 'active current': currentRouteName === item.route }">
            <a href="javascript:void(0);" role="menuitem" @click="itemSelected(item)">
              {{ item.displayname }}
              <span class="sidenav-icon">
                <i class="icon icon-check" v-if="item.valid"></i>
                <i class="icon icon-error" v-else-if="item.hasErrors"></i>
              </span>
              <ul v-if="item.route === 'pejlinger'" class="sidenav-sub_list">
                <li v-for="(boring, boringIndex) in indberetning.boringPejleData" :key="boringIndex">
                  <a href="javascript:void(0);" @click="boringSelected(item, boring, $event)"><span class="sub_list-title">{{index+1}}.{{boringIndex+1}}</span>Boring {{boring.dguNr}}</a>
                </li>
              </ul>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    
    <div class="indberetningActions">
      <button class="button button-secondary gemIndberetning" @click="gemIndberetning()">
          Gem indberetning som kladde <div><i class="icon icon-check gemIndberetningIcon" v-if="indberetningGemt"></i></div>
      </button>
      <div v-if="gemIndberetningMessage" class="gemIndberetningMessage">
        {{gemIndberetningMessage}}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';

import { Dropdown } from 'dkfds';
import { Route } from 'vue-router';
import { Guid } from 'guid-typescript';
import { MwMessageBus } from '@/views/MwMessageBus';
import Indberetning, { BoringPejleData } from '@/models/indberetning';
import { Action, Getter } from 'vuex-class';
import { STORE_NS_INDBERETNING } from '@/constants';
import { HttpResponse } from '@/networking/interfaces';

@Component
export default class MwOverflowMenu extends Vue {

  @Action('UPDATE_INDBERETNING_MIDLERTIDIGT_GEMT', { namespace: STORE_NS_INDBERETNING })
  gemIndberetningMidlertidigtGemt!: (linkId: string) => Promise<HttpResponse>;

  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;

  @Getter('LINKID')
  linkId!: string;
  
  @Prop() items!: any[];
  @Prop({ default: '' }) currentRouteName!: string;

  id: string = 'overflowmenu' + Guid.create().toString();

  indberetningGemt: boolean = false;
  gemIndberetningMessage: string = '';

  @Watch('indberetning', {deep: true})
  indberetningChange() {
    this.indberetningGemt = false;
    this.gemIndberetningMessage = '';
  }

  currentStep() {
    return this.items.findIndex(item => this.currentRouteName === item.route);
  }

  constructor() {
    super();
  }

  itemSelected(item: any) {
    this.$emit('itemSelected', item);
  }

  boringSelected(item: any, boring: BoringPejleData, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.$emit('boringSelected', item, boring);
  }

  gemIndberetning(){
    this.gemIndberetningMidlertidigtGemt(this.linkId).then(() => {
      this.indberetningGemt = true;
      this.gemIndberetningMessage = 'Indberetningen blev gemt som kladde';
      setTimeout(() => {
        this.gemIndberetningMessage = '';
      }, 1500);
    });
  }
}
</script>

<style scoped>
a > div:hover {
  cursor: pointer;
}

.overflow-menu{
  position: fixed;
}

ul {
    padding-left: 0;
    list-style-type: none;
    color: #1a1a1a;
    line-height: 2.6rem;
}

.sidenav-list ul {
    margin: 0;
    margin-top:10px;
    list-style-type: none;
    padding-left: 0;
}
.sidenav-sub_list {
    margin: 0;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    width: 100%;
}

.sidenav-sub_list li {
    border: none;
}
.sidenav-list ul>li {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}
.sidenav-sub_list a {
  overflow: hidden;
  padding-top: 8px;
  line-height: 2.4rem;
}
.sidenav-list>li li {
    border-top: 1px solid #dcdcdc;
    font-size: 1.4rem;
}
.sub_list-title{
  font-weight:bold;
  margin-right:10px;
}
.sidenav-list > li {
  width:280px;
}


.indberetningActions{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
}

.indberetningActions .gemIndberetning{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.indberetningActions .gemIndberetningIcon{
  margin-left:5px;
}

.indberetningActions .gemIndberetningMessage {
    margin-top:6px;
    font-style: italic;
    text-align: center;
}
</style>
