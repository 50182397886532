import axios, { CancelTokenSource } from 'axios';

export class HttpCancelToken {
  source: CancelTokenSource = axios.CancelToken.source();
  cancel(msg?: string) {
    this.source.cancel(msg);
  }
}

export interface HttpResponse {
  status: number;
  data: any;
}

export interface HttpFileResponse extends HttpResponse {
  filename: string;
  mimeType: string;
}

export interface HttpOptions {
  cancelToken: HttpCancelToken | null;
}

export interface QueryOptions extends HttpOptions {
  param1?: string;
  param2?: string;
  paramX?: string;
}
