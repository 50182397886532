import { IndberetningState, RootState } from '@/store/statetypes';
import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import Indberetning from '@/models/indberetning';

export const getDefaultState = () => {
  const defaultState: IndberetningState = {
    indberetning: new Indberetning()
  };
  return defaultState;
};
export const state: IndberetningState = getDefaultState();

const namespaced: boolean = true;

export const indberetningState: Module<IndberetningState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
