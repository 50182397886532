<template>
  <div class="page-table-responsive mb-3">
    <table v-if="filterImportExportData(vandanlaeg.importExportData, type).length >= 1" class="table table--responsive-headers">
      <thead>
        <tr>
          <th>Mængde (m3) *</th>
          <th>Bemærkning (eks. vandværk og årsag)</th>
          <th>Slet</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(importExport, index) in filterImportExportData(vandanlaeg.importExportData, type)" :key="index">
          <td data-title="Mængde (m3)">
            <MwInput
              class="hide-label"
              title="Indskriv mængde"
              :label="'Mængde (m3)'"
              :text.sync="importExport.maengde"
              :validations="
                $v.localVandanlaegVandData.$each[anlaegIndex].importExportData.$each[getIndex(anlaegIndex, importExport)].maengde
              "
              :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
            ></MwInput>
          </td>
          <td data-title="Bemærkning (eks. vandværk og årsag)">
            <MwInput
              class="hide-label"
              :label="'Bemærkning (eks. vandværk og årsag)'"
              title="Indskriv antal"
              :text.sync="importExport.jupiterBemaerkning"
              :disabled="vandanlaeg.oppumpedeMaengderAnlaeg.valideret"
              :validations="
                $v.localVandanlaegVandData.$each[anlaegIndex].importExportData.$each[getIndex(anlaegIndex, importExport)].jupiterBemaerkning
              "
            ></MwInput>
          </td>
          <td
            data-title="Slet"
            class="deleteCenter"
            :class="{ active: filterImportExportData(vandanlaeg.importExportData, type).length >= 1 }"
            @click="deleteImportExportData(anlaegIndex, importExport)"
          >
            <svg id="delete" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"></path>
            </svg>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="addImportExportData">
      <MwAddItemButton @clicked="addImportExportData(anlaegIndex, type)"></MwAddItemButton>
    </div>
  </div>
</template>

<script lang="ts">
import { ImportExportData, VandanlaegVandData } from '@/models/indberetning';
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import { integerCustom, minValueDecimal } from '@/utils/validators';
import MwInput from '@/components/mwUtils/mwInputs/MwInput.vue';
import MwAddItemButton from '@/components/mwUtils/mwButtons/MwAddItemButton.vue';

@Component({
  components: { MwInput, MwAddItemButton },
  validations: {
    localVandanlaegVandData: {
      $each: {
        importExportData: {
          $each: {
            maengde: { required, integerCustom, minValueDecimal: minValueDecimal(0) },
            jupiterBemaerkning: { maxLength: maxLength(100) },
          },
        },
      },
    },
  },
})
export default class ImportExport extends Vue {
  @Prop({ default: null, required: true }) vandanlaeg!: VandanlaegVandData;
  @Prop({ default: null, required: true }) vandanlaegVandData!: VandanlaegVandData[];
  @Prop({ default: null, required: true }) anlaegIndex!: number;
  @Prop({ default: '', required: true }) type!: string;

  localVandanlaegVandData: VandanlaegVandData[] = [];

  created() {
    this.localVandanlaegVandData = this.vandanlaegVandData;
  }

  @Watch('vandanlaegVandData')
  vandanlaegVandDataChanged() {
    this.localVandanlaegVandData = this.vandanlaegVandData;
  }

  filterImportExportData(importExportData: ImportExportData[], type: string) {
    return importExportData.filter(x => x.type === type && x.status !== 3);
  }

  addImportExportData(anlaegIndex: number, type: string) {
    const newImportExport = new ImportExportData();
    newImportExport.type = type;
    newImportExport.importeret = type === 'Importeret';

    this.localVandanlaegVandData[anlaegIndex].importExportData.push(newImportExport);

    this.$emit('update:vandanlaegVandData', this.localVandanlaegVandData);
  }

  deleteImportExportData(anlaegIndex: number, importExport: ImportExportData) {
    const index = this.localVandanlaegVandData[anlaegIndex].importExportData.findIndex(item => item === importExport);
    this.localVandanlaegVandData[anlaegIndex].importExportData[index].status = 3;

    this.$emit('update:vandanlaegVandData', this.localVandanlaegVandData);
  }

  getIndex(anlaegIndex: number, importExport: ImportExportData) {
    return this.localVandanlaegVandData[anlaegIndex].importExportData.findIndex(item => item === importExport);
  }
}
</script>

<style scoped>
.deleteCenter {
  margin-top: 8px;
  width: 60px;
  height: 70px;
  pointer-events: none;
  opacity: 0;
}

.deleteCenter.active {
  cursor: pointer;
  pointer-events: all;
  opacity: 1;
}

.deleteCenter svg {
  margin-top: 5px;
}

.addImportExportData {
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
