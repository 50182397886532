import Vue from 'vue';
import Router, { Route } from 'vue-router';

import Store from '@/store/store';

import Home from './views/Home.vue';
import Maengder from './views/Maengder.vue';
import Pejlinger from './views/Pejlinger.vue';
import Opsummering from './views/Opsummering.vue';
import Kvittering from './views/Kvittering.vue';
import Wizard from '@/views/Wizard.vue';
import Indberetning from '@/views/Indberetning.vue';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';
import NotFound from '@/views/NotFound.vue';

Vue.use(Router);

const router: Router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/indberetning',
      component: Wizard
    },
    {
      path: '/indberetning',
      name: 'indberetning',
      component: Indberetning
    },
    {
      path: '/indberetning/:id',
      redirect: '/indberetning/:id/home',
      name: 'indberet',
      component: Wizard,
      async beforeEnter(to: Route, _, next: () => void) {
        Store.commit('SET_LINKID', to.params.id);
        Store.commit('SET_LINKID_STATUS', '');

        const { status, data } = await Store.dispatch(`${STORE_NS_INDBERETNING}/FETCH_HENTINDBERETNINGINFO`, to.params.id);
        if (status === 200) {
          await Store.dispatch(`${STORE_NS_LOOKUPS}/FETCH_INDBERETNINGLOOKUPS`);
          Store.commit('SET_LINKID_STATUS', 'VALID');
          if (to.name !== 'home') {
            router.push({
              name: 'home',
              params: {
                id: to.params.id
              }
            });
          } else {
            next();
          }
          await Store.dispatch(`${STORE_NS_INDBERETNING}/FETCH_INDBERETNING_MIDLERTIDIGT_GEMT`, to.params.id);
        } else {
          Store.commit('SET_LINKID_STATUS', 'INVALID');
          router.push({ path: '/' });
        }
      },
      children: [
        {
          path: '/indberetning/:id/home',
          name: 'home',
          component: Home,
          props: {
            displayname: '1. Introduktion'
          }
        },
        {
          path: '/indberetning/:id/maengder',
          name: 'maengder',
          component: Maengder,
          props: {
            displayname: '2. Indberet mængder'
          }
        },
        {
          path: '/indberetning/:id/pejlinger',
          name: 'pejlinger',
          component: Pejlinger,
          props: {
            displayname: '3. Indberet pejlinger'
          }
        },
        {
          path: '/indberetning/:id/opsummering',
          name: 'opsummering',
          component: Opsummering,
          props: {
            displayname: '4. Opsummering'
          }
        }
      ]
    },
    {
      path: '/indberetning/:id/kvittering',
      name: 'kvittering',
      component: Kvittering
    },
    { path: '/404', name: '404', component: NotFound },
    { path: '*', redirect: '/404', props: { breadcrumbName: 'Ikke fundet', breadcrumbUrl: '404' } }
  ]
});

export default router;
