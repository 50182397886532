<template>
  <header class="header">
    <!-- Start: Portal header -->

    <!-- Slut: Portal header -->

    <!-- Start: Løsningsheader -->
    <div class="solution-header">
      <div class="container solution-header-inner">
        <!-- Start: Løsningstitel -->
        <div class="solution-heading">
          <a href="#">Indberetning af vandmængder og pejlinger</a>
        </div>
        <!-- Slut: Løsningstitel -->

        <!-- Start: Information om myndighed -->
        <div class="solution-info">
          <p class="h5 authority-name" v-if="indberetning.afdelingsNavn">{{ indberetning.afdelingsNavn }}</p>
          <p>
            <span> Support: <a :href="'tel:' + indberetning.afdelingTelefon" aria-label="Support"></a></span>
            <span class="d-print-none">
              {{ indberetning.afdelingTelefon }} - Email:
              <a :href="'mailto:' + indberetning.afdelingEmail" data-module="modal" class="function-link d-inline-block" type="button">
                {{ indberetning.afdelingEmail }}
              </a>
            </span>
          </p>
        </div>
        <!-- Slut: Information om myndighed -->
      </div>
    </div>
    <!-- Slut: Løsningsheader -->

    <!-- Start: Navigation (Valgfri) -->
    <div class="overlay"></div>
    <nav class="nav">
      <button class="button button-secondary button-menu-close js-menu-close">
        <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#close"></use></svg>Luk
      </button>

      <!-- Start: Hovedmenu -->
      <div class="navbar navbar-primary">
        <div class="navbar-inner container">
          <!-- Slut: Hovedmenu -->
        </div>
      </div>
      <!-- Start: Sekundær menu (Valgfri) -->
      <div class="navbar">
        <div class="navbar-inner container">
          <!-- <div class="nav-secondary">
            <ul class="unstyled-list nav-actions">
              <li>
                <div class="overflow-menu overflow-menu--open-right">
                  <button
                    class="button-overflow-menu js-dropdown js-dropdown--responsive-collapse"
                    data-js-target="headeroverflow3"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Overflow menu
                    <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#menu-down"></use></svg>
                  </button>
                  <div class="overflow-menu-inner" id="headeroverflow3" aria-hidden="true">
                    <ul class="overflow-list">
                      <li><a href="#">Menupunkt 1</a></li>
                      <li><a href="#">Et menupunkt 2</a></li>
                      <li><a href="#">Tredje menupunkt 3</a></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div> -->

          <!-- <ul class="unstyled-list nav-actions">
            <li>
              <a href="#" class="button button-tertiary">Tertiærknap</a>
            </li>
            <li>
              <a href="#" class="button button-tertiary">Tertiærknap</a>
            </li>
            <li>
              <a href="#" class="button button-tertiary">Tertiærknap</a>
            </li>
          </ul> -->
        </div>
      </div>
      <!-- Slut: Sekundær menu -->

      <!-- Start: Ekstra række (Valgfri) -->
      <!-- <div class="navbar">
        <div class="navbar-inner navbar-context-actions container">
          <div class="nav-actions">
            <a href="" class="function-link"
              ><svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#printer"></use></svg>Funktionsikon</a
            >
            <a href="" class="function-link"
              ><svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#content-save"></use></svg>Funktionsikon</a
            >
          </div>
        </div>
      </div> -->
      <!-- Slut: Ekstra række -->

      <!-- Start: Informationer vist i mobilmenu -->
      <!-- <div class="portal-info-mobile">
        <p class="user">Christian Emil Vestergaard Christensen</p>
        <p>Københavns Urmager og Værksted v/Martin Elsig</p>
        <a href="#" class="button button-secondary alert-leave" role="button">
          Log af
        </a>
      </div>

      <div class="solution-info-mobile">
        <p class="h5 authority-name">Myndighedsnavn</p>
        <p>
          Support: 12 34 56 78 ·
          <a href="javascript:void(0);" class="function-link d-inline-block">
            <svg class="icon-svg" aria-hidden="true" focusable="false"><use xlink:href="#card-text-outline"></use></svg>
            Kontakt
          </a>
        </p>
      </div> -->
      <!-- Slut: Informationer vist i mobilmenu -->
    </nav>
    <!-- Slut: Navigation -->
  </header>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';
//@ts-ignore

import { HttpResponse } from '@/networking/interfaces';
import MwNavItems from '@/components/header/MwNavItems.vue';
import Indberetning from '@/models/indberetning';
import { STORE_NS_INDBERETNING } from '@/constants';

@Component({
  components: { MwNavItems }
})
export default class Header extends Vue {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;
}
</script>

<style scoped>
</style>
