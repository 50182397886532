import { MutationTree } from 'vuex';
import { LookupState, RootState } from '@/store/statetypes';
import { Lookup } from '@/models/lookup';
import { getDefaultState } from '.';

export const mutations: MutationTree<LookupState> = {
  UPDATE_LOOKUPLIST(state, payload) {
    (state as any)[payload.key] = payload.data;
  },

  CLEARSTATE(state) {
    Object.assign(state, getDefaultState());
  }
};
