<template>
  <tr>
    <th class="w-percent-md-30">{{ itemTitle }}</th>
    <!-- <td>{{ itemText }}</td> -->
    <td><slot></slot></td>
  </tr>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MwOpsummeringItem extends Vue {
  // @Prop({ default: '' }) header!: string;
  @Prop({ default: '' }) itemTitle!: any;
  @Prop({ default: true }) showEditLinks!: boolean;
}
</script>
<style scoped></style>
