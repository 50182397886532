<template>
  <div class="">
    <h1 class="hide-item">Velkommen</h1>
    <p v-html="indberetning.velkommenTekst"></p>
    <nav class="page-navigation d-print-none">
      <button class="button button-primary" @click="goToRoute('maengder')" type="button">
        Videre
      </button>
    </nav>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
//@ts-ignore
import MwOverflowMenu from '@/components/header/MwOverflowMenu.vue';
import MwNavItems from '@/components/header/MwNavItems.vue';
import MwAlert from '@/components/mwUtils/alerts/MwAlert.vue';
import { STORE_NS_INDBERETNING } from '@/constants';
import Indberetning from '@/models/indberetning';
import WizardHelper from '@/mixins/WizardHelper';
// import router

@Component({
  components: {
    MwNavItems,
    MwOverflowMenu,
    MwAlert
  }
})
export default class Home extends Mixins(WizardHelper) {
  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;
}
</script>

<style>
.hide-item {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
</style>
