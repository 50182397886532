import { ActionTree } from 'vuex';
import { RootState, IndberetningState } from '@/store/statetypes';
import { QueryOptions, HttpResponse } from '@/networking/interfaces';
import { IndberetningContext } from '@/store/statetypes';

import * as httpClient from '@/networking/httpclient';
import UrlBuilder from '@/networking/urlBuilder';
import Indberetning, { VandanlaegVandData, Forsyning } from '@/models/indberetning';

export const actions: ActionTree<IndberetningState, RootState> = {
  async FETCH_HENTINDBERETNINGINFO({ commit }: IndberetningContext, linkId: string) {
    // const urlBuilder = new UrlBuilder('/EksternVand/hentIndberetningInfo');

    // urlBuilder.addParameter('param1', options.param1);
    // urlBuilder.addParameter('param2', options.param2);
    // urlBuilder.addParameter('paramX', options.paramX);

    const { status: status, data } = await httpClient.get('/EksternVand/hentIndberetningInfo?linkId=' + linkId).catch(errorObj => {
      const response = errorObj.response;
      return { status: response.status, data: response.data };
    });

    if (status === 200) {
      commit('SET_INDBERETNING', data);
    }

    return { status, data };
  },

  async UPDATE_INDBERETNINGINFO({ getters }: IndberetningContext, linkId: string) {
    const data = getters.INDBERETNING;
    const { status: status } = await httpClient.put('/EksternVand/gemIndberetningInfo?linkId=' + linkId, data);
    return { status, data };
  },

  async SUM_FORSYNINGSARTER({ getters }: IndberetningContext, forsyningsarter: Forsyning[]) {
    const { status, data } = await httpClient.post('/EksternVand/sumForsyningsarter', forsyningsarter);
    return { status, data };
  },

  async BEREGN_MAENGDEAFGANGVANDVAERK({ getters }: IndberetningContext, vandalaegvanddata: VandanlaegVandData) {
    const { status, data } = await httpClient.post('/EksternVand/OppumpedeMaengderBeregnedeFelter', vandalaegvanddata);
    return { status, data };
  },

  async FETCH_INDBERETNING_MIDLERTIDIGT_GEMT({ state, commit }, linkId: string) {
    const { status, data } = await httpClient.get('EksternVand/HentIndberetningMidlertidigt?linkId=' + linkId);
    if (data !== null && status === 200) {
      const indberetning: Indberetning = data;
      commit('SET_INDBERETNING', indberetning);
    }
  },

  async RESET_INDBERETNING_MIDLERTIDIGT_GEMT({ state, commit }, linkId: string) {
    const { status, data } = await httpClient.get('EksternVand/NulstilIndberetningMidlertidigt?linkId=' + linkId);
  },

  async UPDATE_INDBERETNING_MIDLERTIDIGT_GEMT({ getters, state, commit }, linkId: string) {
    await httpClient.put('EksternVand/GemIndberetningMidlertidigt', {
      linkId,
      midlertidigtGem: JSON.stringify(state.indberetning)
    });
  },

  CLEARSTATE({ commit }): void {
    commit('CLEARSTATE');
  }
};
