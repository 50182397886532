import { IndberetningState, RootState } from '@/store/statetypes';
import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { Lookup } from '@/models/lookup';
import { LookupState } from '@/store/statetypes';

export const getDefaultState = () => {
  const defaultState: LookupState = {
    indvindingsmetode: [],
    jupiterPejleSituation: [],
    jupiterPejleMetode: [],
    jupiterReferencepunkt: [],
    jupiterPejleEkstremer: [],
    jupiterForsyningsart: []
  };
  return defaultState;
};
export const state: LookupState = getDefaultState();

const namespaced: boolean = true;

export const lookupState: Module<LookupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
