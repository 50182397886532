<template>
  <section>
    <h3 class="mt-0 mb-0" v-for="(anlaeg, anlaegIndex) in indberetning.vandanlaegVandData" :key="anlaegIndex">
      For: {{ anlaeg.navn + ', ' + anlaeg.adresse + ' (' + anlaeg.jupiterId + ')' }}
    </h3>
    <div v-if="indberetning.vandanlaegVandData && indberetning.vandanlaegVandData.length > 0">
      <h2>Indberettede mængder</h2>

      <div v-for="(anlaeg, anlaegIndex) in indberetning.vandanlaegVandData" :key="anlaegIndex">
        <MwOpsummering>
          <MwOpsummeringItem header="" itemTitle="Periode fra">
            {{ formatDate(anlaeg.oppumpedeMaengderAnlaeg.startdato) }}
          </MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Periode til">{{ formatDate(anlaeg.oppumpedeMaengderAnlaeg.slutdato) }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Målerstart"> {{ anlaeg.oppumpedeMaengderAnlaeg.maalerstandStart }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Målerslut"> {{ anlaeg.oppumpedeMaengderAnlaeg.maalerstandSlut }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Metode for måling ">
            {{ indvindingsmetode(anlaeg.oppumpedeMaengderAnlaeg.jupiterIndvindingsmetodeId) }}</MwOpsummeringItem
          >
          <MwOpsummeringItem header="" itemTitle="Omregningsfaktor ">
            {{ anlaeg.oppumpedeMaengderAnlaeg.omregningsfaktor }}</MwOpsummeringItem
          >
          <MwOpsummeringItem header="" itemTitle="Grundvand (m3) "> {{ anlaeg.oppumpedeMaengderAnlaeg.grundvand }}</MwOpsummeringItem>

          <MwOpsummeringItem header="" itemTitle="Sidste års mængde (m3) ">{{ anlaeg.sidsteAarsMaengde }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Bemærkning">{{ anlaeg.oppumpedeMaengderAnlaeg.bemaerkning }}</MwOpsummeringItem>
        </MwOpsummering>

        <table
          class="table table--borderless  table--extracompact table--responsive-headers d-print-table mt-6 mb-7 "
          v-if="anlaeg.oppumpedeMaengderAnlaeg.fasteForsyning !== null && anlaeg.oppumpedeMaengderAnlaeg.fasteForsyning.length > 0"
        >
          <thead>
            <tr>
              <th>Type</th>
              <th>Mængde (m3)</th>
              <th>Bemærkning</th>
              <!-- <th>Modtager antal</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(fastForsyning, fastForsyningIndex) in anlaeg.oppumpedeMaengderAnlaeg.fasteForsyning" :key="fastForsyningIndex">
              <th data-title="Type">
                {{
                  fastForsyning.forsyningsArtNavn === 'Samlet leveret vandmængde'
                    ? 'Mængde afgang vandværk m3'
                    : fastForsyning.forsyningsArtNavn
                }}
              </th>
              <td data-title="Mængde (m3)">
                {{ fastForsyning.maengde }}
              </td>
              <td data-title="Bemærkning">
                {{ fastForsyning.bemaerkning }}
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="anlaeg.importExportData !== null && anlaeg.importExportData.length > 0">
          <h2 class="h3">Importerede og eksporterede vandmængde</h2>
          <table
            class="table table--responsive-headers table table--borderless  table--extracompact table--responsive-headers d-print-table mt-6 mb-7 "
          >
            <thead>
              <tr>
                <th>Type</th>
                <th>Mængde (m3)</th>
                <th>Bemærkning (eks. vandværk og årsag)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(importExportData, importExportIndex) in filterImportExportData(anlaeg.importExportData)" :key="importExportIndex">
                <template v-if="importExportData.importeret === true"> <th data-title="Type">Importeret</th></template>
                <template v-else> <th data-title="Type">Eksporteret</th></template>

                <td data-title="Mængde (m3)">
                  {{ importExportData.maengde }}
                  <!-- <MwInput title="Indskriv mængde" :text="anlaeg.overfladevand" :validations="$v.Overfladevand"></MwInput> -->
                </td>
                <td data-title="Bemærkning (eks. vandværk og årsag)">
                  {{ importExportData.jupiterBemaerkning }}
                  <!-- <MwInput title="Indskriv antal" :text="anlaeg.overfladevand" :validations="$v.Overfladevand"></MwInput> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="anlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning !== null && anlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning.length > 0">
          <h2 class="h3">
            {{ anlaeg.erStorVandanlaeg ? 'Solgte vandmængder fordelt på forsyningsart' : 'Vandmængde fordelt på forsyningsarter' }}
          </h2>
          <table
            class="table table--responsive-headers table table--borderless  table--extracompact table--responsive-headers d-print-table mt-6 mb-7"
          >
            <thead>
              <tr>
                <th>Forsyningsart</th>
                <th>Mængde (m3)</th>
                <th>Modtager antal</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(ekstraForsyning, ekstraForsyningIndex) in anlaeg.oppumpedeMaengderAnlaeg.ekstraForsyning"
                :key="ekstraForsyningIndex"
              >
                <td data-title="Forsyningsart">{{ ekstraForsyning.forsyningsArtNavn }}</td>
                <td data-title="Mængde (m3)">
                  {{ ekstraForsyning.maengde }}
                </td>
                <td data-title="Modtager antal">
                  {{ ekstraForsyning.modtagerAntal }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2 class="h3" v-if="indberetning.boringVandData && indberetning.boringVandData.length">Oppumpede mængder på boringer</h2>

        <div v-for="(boring, boringIndex) in indberetning.boringVandData" :key="boringIndex">
          <MwOpsummering v-if="boring.oppumpedeMaengder">
            <tr style="border-top:none!important">
              <td colspan="3" style="border-top:none!important">
                <h3 class="mt-0 h5">Boring med DGUnr = {{ boring.dguNr }}</h3>
              </td>
            </tr>
            <MwOpsummeringItem header="" itemTitle="Periode fra">
              {{ formatDate(boring.oppumpedeMaengder.startdato) }}
            </MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Periode til"> {{ formatDate(boring.oppumpedeMaengder.slutdato) }}</MwOpsummeringItem>

            <!-- <MwOpsummeringItem header="" itemTitle="Overfladevand (m3)" >{{
                            anlaeg.maalerstandSlut
                          }}</MwOpsummeringItem> -->
            <MwOpsummeringItem header="" itemTitle="Målerstart ">{{ boring.oppumpedeMaengder.maalerstandStart }}</MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Målerslut ">{{ boring.oppumpedeMaengder.maalerstandSlut }}</MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Metode for måling ">{{
              indvindingsmetode(boring.oppumpedeMaengder.jupiterIndvindingsmetodeId)
            }}</MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Omregningsfaktor ">{{ boring.oppumpedeMaengder.omregningsfaktor }}</MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Grundvand (m3)">{{ boring.oppumpedeMaengder.grundvand }}</MwOpsummeringItem>

            <MwOpsummeringItem header="" itemTitle="Sidste års mængde (m3) ">{{ boring.sidsteAarsMaengde }}</MwOpsummeringItem>
            <MwOpsummeringItem header="" itemTitle="Bemærkning">{{ boring.oppumpedeMaengder.bemaerkning }}</MwOpsummeringItem>
          </MwOpsummering>
        </div>
      </div>
    </div>
    <div v-if="indberetning.boringPejleData && indberetning.boringPejleData.length > 0">
      <h2>Indberettede pejlinger</h2>
      <div v-for="(pejleData, pejleDataIndex) in indberetning.boringPejleData" :key="pejleDataIndex">
        <h3 class="h4 mb-3">Boring med DGUnr = {{ pejleData.dguNr }} Indtagsnr: {{ pejleData.indtagNummer }}</h3>
        <MwOpsummering v-if="pejleData.pejlinger.length > 0">
          <MwOpsummeringItem header="" itemTitle="Pejlemetode">{{ jupiterPejleMetode(pejleData.jupiterPejleMetodeId) }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Reference til pejlepunkt">{{
            jupiterReferencepunkt(pejleData.jupiterReferencepunktId)
          }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle=" Bemærkning" :itemText="pejleData.bemaerkning">{{
            pejleData.bemaerkning
          }}</MwOpsummeringItem>
        </MwOpsummering>

        <MwOpsummering v-for="(pejling, pejlingIndex) in pejleData.pejlinger" :key="pejlingIndex">
          <tr style="border-top:none!important">
            <td colspan="3" style="border-top:none!important">
              <p class="bold mt-0">
                {{ 'Pejling ' + formatDate(pejling.dato) + (pejling.feltmaaling != null ? ' - måling ' + pejling.feltmaaling : '') }}
              </p>
            </td>
          </tr>

          <MwOpsummeringItem header="" itemTitle="Datotid for pejling"> {{ formatDateTime(pejling.dato) }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Situation">{{
            jupiterPejleSituation(pejling.jupiterPejleSituationId)
          }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Antal timer siden pumpestop ">{{ pejling.timerSidenPumpestop }}</MwOpsummeringItem>
          <MwOpsummeringItem header="" itemTitle="Måling">{{ pejling.feltmaaling }}</MwOpsummeringItem>
        </MwOpsummering>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Prop, Vue, Component, Mixins } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VueRouter, { Route } from 'vue-router';

import { HttpResponse } from '@/networking/interfaces';
import { STORE_NS_INDBERETNING, STORE_NS_LOOKUPS } from '@/constants';

import MwOpsummering from '@/components/mwUtils/opsummering/MwOpsummering.vue';
import MwOpsummeringItem from '@/components/mwUtils/opsummering/MwOpsummeringItem.vue';
import Indberetning, { ImportExportData } from '@/models/indberetning';
import WizardHelper from '@/mixins/WizardHelper';
import moment from 'moment';

@Component({
  components: { MwOpsummering, MwOpsummeringItem }
  // validations: {
  //   grundvand: { required, minLength: minLength(5) },
  //   overfladevand: { required, minLength: minLength(5) },
  //   maalerStart: { required, minLength: minLength(5) },
  //   maalerSlut: { required, minLength: minLength(5) }
  // }
})
export default class OpsummeringKvittering extends Mixins(WizardHelper) {
  @Action('UPDATE_INDBERETNINGINFO', { namespace: STORE_NS_INDBERETNING })
  gemIndberetningInfo!: (linkId: string) => Promise<HttpResponse>;

  @Getter('INDBERETNING', { namespace: STORE_NS_INDBERETNING })
  indberetning!: Indberetning;

  @Getter('LINKID')
  linkId!: string;

  @Getter('INDBERETNINGLOOKUPS', { namespace: STORE_NS_LOOKUPS })
  lookups!: any;

  formatDate(date: string) {
    return moment(date).format('DD-MM-YYYY');
  }

  formatDateTime(date: string) {
    return moment(date).format('DD-MM-YYYY HH:mm');
  }

  validate(): boolean {
    return true;
  }

  indvindingsmetode(id: string) {
    if (id == null) {
      return '';
    }

    if (this.lookups.indvindingsmetode.length > 0) {
      return this.lookups.indvindingsmetode.find((indvinding: any) => indvinding.id === id).navn;
    } else {
      return '';
    }
  }
  jupiterPejleSituation(id: string) {
    if (id == null) {
      return '';
    }

    if (this.lookups.jupiterPejleSituation.length > 0) {
      return this.lookups.jupiterPejleSituation.find((situation: any) => situation.id === id).navn;
    } else {
      return '';
    }
  }
  jupiterPejleMetode(id: string) {
    if (id == null) {
      return '';
    }

    if (this.lookups.jupiterPejleMetode.length > 0) {
      return this.lookups.jupiterPejleMetode.find((metode: any) => metode.id === id).navn;
    } else {
      return '';
    }
  }
  jupiterReferencepunkt(id: string) {
    if (id == null) {
      return '';
    }

    if (this.lookups.jupiterReferencepunkt.length > 0) {
      return this.lookups.jupiterReferencepunkt.find((refPunkt: any) => refPunkt.id === id).navn;
    } else {
      return '';
    }
  }

  filterImportExportData(importExportData: ImportExportData[]) {
    //Eksporteret skal være før importeret
    return importExportData.filter(element => element.status !== 3).sort((a, b) => a.type.localeCompare(b.type));
  }
}
</script>

<style scoped>
.border-none {
  border: none !important;
  width: max-content;
}
</style>
