import moment from 'moment';
export default class Indberetning {
  aar: number = 0;
  afdelingEmail: string = '';
  afdelingTelefon: string = '';
  afdelingsNavn: string = '';
  boringPejleData: BoringPejleData[] = [];
  boringVandData: BoringVandData[] = [];
  kvitteringTekst: string = '';
  kvitteringsMail: string = '';
  linkTilPrivatlivspolitik: string = '';
  linkTilWebtilgaengelighed: string = '';
  vandanlaegVandData: VandanlaegVandData[] = [];
  velkommenTekst: string = '';
}

export class BoringPejleData {
  dguNr: string = '';
  indtagId: string = '';
  indtagNummer: number = 0;
  pejlinger: Pejling[] = [];

  terraenkote: string = '';
  pejlepunktKote: string = '';

  // defineres på Indtag/boring niveau i ekstern løsing, men gemmes i databasen per pejling
  jupiterPejleMetodeId: string = '';
  jupiterReferencepunktId: string = '';
  bemaerkning: string = '';
  erStorVandanlaeg: boolean = true;
}

export class BoringVandData {
  dguNr: string = '';
  indtagId: string = '';
  indtagNummer: number = 0;
  oppumpedeMaengder: OppumpedeMaengder = new OppumpedeMaengder();
  sidsteAarsMaengde: string = '0';
}

export class VandanlaegVandData {
  adresse: string = '';
  importExportData: ImportExportData[] = [];
  jupiterId: number = 0;
  navn: string = '';
  oppumpedeMaengderAnlaeg: OppumpedeMaengderAnlaeg = new OppumpedeMaengderAnlaeg();
  sidsteAarsMaengde: string = '0';
  vandanlaegKunOppumpetMaengder: boolean = false;
  erStorVandanlaeg: boolean = false;
  virksomhedId: string = '';
}

export class OppumpedeMaengderAnlaeg {
  fasteForsyning: Forsyning[] = [];
  ekstraForsyning: Forsyning[] = [];
  grundvand: number | null = 0;
  id: string = '';
  jupiterIndvindingsmetodeId: string = '';
  maalerstandStart: number | null = 0;
  maalerstandSlut: number | null = 0;
  omregningsfaktor: number = 0;
  oppumpetFra: string = '';
  bemaerkning: string = '';
  startdato: moment.Moment = moment();
  slutdato: moment.Moment = moment();
  status: number = 0;
  valideret: boolean = false;
  vandmaalerSubMetode?: number = undefined;
}

export class ImportExportData {
  id: string = '';
  maengde!: number;
  importeret: boolean = false;
  jupiterBemaerkning: string = '';
  status: number = 0;
  type: string = '';
  valideret: boolean = false;
}
export class Forsyning {
  id: string = '';
  status: number = 0;
  forsyningsArtId: string = '';
  forsyningsArtNavn: string = '';
  forsyningsArtKode: string | undefined;
  maengde: number = 0;
  bemaerkning: string = '';
  modtagerAntal: number = 0;
}

export class IndberetningDataBoring {
  indtagId: string = '';
  dguNr: string = '';
  indtagNummer: number = 0;
  sidsteAarsMaengde: string = '';
  oppumpedeMaengder: OppumpedeMaengder[] = [];
  pejlinger: Pejling[] = [];
  // id: string = '';
  // status: number = 0;
  // jupiterIndvindingsmetodeId: string = '';
  // startdato: moment.Moment = moment();
  // slutdato: moment.Moment = moment();
  // maalerstandStart: number = 0;
  // maalerstandSlut: number = 0;
  // omregningsfaktor: number = 0;
  // grundvand: number = 0;
}

export class OppumpedeMaengder {
  id: string = '';
  status: number = 0;
  jupiterIndvindingsmetodeId: string = '';
  startdato: moment.Moment = moment();
  slutdato: moment.Moment = moment();
  maalerstandStart: number = 0;
  maalerstandSlut: number = 0;
  omregningsfaktor: number = 0;
  grundvand: number = 0;
  bemaerkning: string = '';
  vandmaalerSubMetode?: number = undefined;
  valideret: boolean = false;
}

export class Pejling {
  //nye

  id: string = '';
  status: number = 0;
  dato: moment.Moment = moment();
  jupiterPejleSituationId: string = '';
  jupiterPejleEkstremerId: string | null = null;
  //jupiterKotesystemId: string = '';
  //jupiterPejlekatogoriId: string = '';
  //jupiterPejleKvalitetId: string = '';
  //jupiterPejleProjektId: string = '';
  //jupiterPejleEkstremerId: string = '';

  timerSidenPumpestop: number = 0;
  //pejlemaalepunktKote: number = 0;
  feltmaaling: number | null = null;
  valideret: boolean = false;
  //beregnetPejling: number = 0;

  expanded: boolean = true;
}

export class VandmaalerSubMetode {
  constructor(id: number, methodName: string) {
    this.id = id;
    this.methodName = methodName;
  }
  id: number;
  methodName: string;

  static MaalerAflaesning = new VandmaalerSubMetode(0, 'Angiv ved hjælp af målerstart, målerslut');
  static SummeretMaengde = new VandmaalerSubMetode(1, 'Angiv ved hjælp af oppumpet summeret mængde');

  static methods: VandmaalerSubMetode[] = [VandmaalerSubMetode.MaalerAflaesning, VandmaalerSubMetode.SummeretMaengde];
}
