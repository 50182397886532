<template>
  <button class="button button-secondary " :title="title" @click="clicked">
    <i class="icon icon-add" />
    <span style="vertical-align: super;">
      {{text}}
    </span>
  </button>
</template>

<script lang="ts">
import { Prop, Component, Vue } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';

@Component
export default class MwAddItemButton extends Vue {
  @Prop({ default: 'Tilføj' }) text!: string;
  @Prop({ default: '' }) title!: string;

  @Prop({ default: null }) validations!: vuelidate.Params;

  clicked(event: any) {
    this.$emit('clicked', event);
    // if (this.validationError) {
    //   this.validateInput();
    // }
  }
}
</script>

<style scoped>

</style>
